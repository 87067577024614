@charset "UTF-8";
@font-face {
  font-family: "Siemens Serif";
  font-weight: normal;
  font-style: normal;
  src: local("Siemens Serif Roman"), url("../fonts/SiemensSerif_Prof_Roman.woff2") format("woff2"), url("../fonts/SiemensSerif_Prof_Roman.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: normal;
  font-style: italic;
  src: local("Siemens Serif Roman Italic"), url("../fonts/SiemensSerif_Prof_Italic.woff2") format("woff2"), url("../fonts/SiemensSerif_Prof_Italic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: 600;
  font-style: normal;
  src: local("Siemens Serif Semibold"), url("../fonts/SiemensSerif_Prof_Semibold.woff2") format("woff2"), url("../fonts/SiemensSerif_Prof_Semibold.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: 600;
  font-style: italic;
  src: local("Siemens Serif Semibold Italic"), url("../fonts/SiemensSerif_Prof_SemiboldItalic.woff2") format("woff2"), url("../fonts/SiemensSerif_Prof_SemiboldItalic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: bold;
  font-style: normal;
  src: local("Siemens Serif Bold"), url("../fonts/SiemensSerif_Prof_Bold.woff2") format("woff2"), url("../fonts/SiemensSerif_Prof_Bold.woff") format("woff");
}
@font-face {
  font-family: "Siemens Serif";
  font-weight: bold;
  font-style: italic;
  src: local("Siemens Serif Bold Italic"), url("../fonts/SiemensSerif_Prof_BoldItalic.woff2") format("woff2"), url("../fonts/SiemensSerif_Prof_BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Siemens Sans";
  font-weight: normal;
  font-style: normal;
  src: local("Siemens Sans Roman"), url("../fonts/SiemensSans_Prof_Roman.woff2") format("woff2"), url("../fonts/SiemensSans_Prof_Roman.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: normal;
  font-style: italic;
  src: local("Siemens Sans Roman Italic"), url("../fonts/SiemensSans_Prof_Italic.woff2") format("woff2"), url("../fonts/SiemensSans_Prof_Italic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: bold;
  font-style: normal;
  src: local("Siemens Sans Bold"), url("../fonts/SiemensSans_Prof_Bold.woff2") format("woff2"), url("../fonts/SiemensSans_Prof_Bold.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: bold;
  font-style: italic;
  src: local("Siemens Sans Bold Italic"), url("../fonts/SiemensSans_Prof_BoldItalic.woff2") format("woff2"), url("../fonts/SiemensSans_Prof_BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: 900;
  font-style: normal;
  src: local("Siemens Sans Black"), url("../fonts/SiemensSans_Prof_Black.woff2") format("woff2"), url("../fonts/SiemensSans_Prof_Black.woff") format("woff");
}
@font-face {
  font-family: "Siemens Sans";
  font-weight: 900;
  font-style: italic;
  src: local("Siemens Sans Black Italic"), url("../fonts/SiemensSans_Prof_BlackItalic.woff2") format("woff2"), url("../fonts/SiemensSans_Prof_BlackItalic.woff") format("woff");
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #008aa6;
  --bs-secondary: #0cc;
  --bs-success: #01893a;
  --bs-info: #007eb1;
  --bs-warning: #e96401;
  --bs-danger: #d72339;
  --bs-light: #fff;
  --bs-dark: #000028;
  --bs-inactive: #8b8b95;
  --bs-primary-rgb: 0, 138, 166;
  --bs-secondary-rgb: 0, 204, 204;
  --bs-success-rgb: 1, 137, 58;
  --bs-info-rgb: 0, 126, 177;
  --bs-warning-rgb: 233, 100, 1;
  --bs-danger-rgb: 215, 35, 57;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 0, 0, 40;
  --bs-inactive-rgb: 139, 139, 149;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 0, 0, 40;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Siemens Sans";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000028;
  --bs-body-bg: #fff;
}

:root {
  --si-brand-color: #099;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 900;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 3.75rem;
}

h2, .h2 {
  font-size: 2.5rem;
}

h3, .h3 {
  font-size: 2.25rem;
}

h4, .h4 {
  font-size: 2rem;
}

h5, .h5 {
  font-size: 1.5rem;
}

h6, .h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 40px;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.75rem;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007993;
  text-decoration: none;
}
a:hover {
  color: #005159;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.75rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.75rem;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #212529;
  border-radius: 0;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #7d8099;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.6875rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

*:focus-visible {
  outline: 2px solid #199fff !important;
  outline-offset: 2px;
  z-index: 1;
  position: relative;
}

*:active {
  outline: none;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  --form-invalid-color: #ef0137;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

table {
  border-spacing: 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.75rem;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 570px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 780px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.5rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000028;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #000028;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #196269;
  --bs-table-hover-bg: #d1fff2;
  width: 100%;
  margin-bottom: 1rem;
  color: #000028;
  vertical-align: top;
  border-color: #b3b3be;
}
.table > :not(caption) > * > * {
  padding: 12px 12px;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cce8ed;
  --bs-table-striped-bg: #c2dce3;
  --bs-table-striped-color: #000028;
  --bs-table-active-bg: #b8d1d9;
  --bs-table-active-color: #000028;
  --bs-table-hover-bg: #bdd7de;
  --bs-table-hover-color: #000028;
  color: #000028;
  border-color: #b8d1d9;
}

.table-secondary {
  --bs-table-bg: #ccf5f5;
  --bs-table-striped-bg: #c2e9eb;
  --bs-table-striped-color: #000028;
  --bs-table-active-bg: #b8dde1;
  --bs-table-active-color: #000028;
  --bs-table-hover-bg: #bde3e6;
  --bs-table-hover-color: #000028;
  color: #000028;
  border-color: #b8dde1;
}

.table-success {
  --bs-table-bg: #cce7d8;
  --bs-table-striped-bg: #c2dbcf;
  --bs-table-striped-color: #000028;
  --bs-table-active-bg: #b8d0c6;
  --bs-table-active-color: #000028;
  --bs-table-hover-bg: #bdd6cb;
  --bs-table-hover-color: #000028;
  color: #000028;
  border-color: #b8d0c6;
}

.table-info {
  --bs-table-bg: #cce5ef;
  --bs-table-striped-bg: #c2dae5;
  --bs-table-striped-color: #000028;
  --bs-table-active-bg: #b8cedb;
  --bs-table-active-color: #000028;
  --bs-table-hover-bg: #bdd4e0;
  --bs-table-hover-color: #000028;
  color: #000028;
  border-color: #b8cedb;
}

.table-warning {
  --bs-table-bg: #fbe0cc;
  --bs-table-striped-bg: #eed5c4;
  --bs-table-striped-color: #000028;
  --bs-table-active-bg: #e2cabc;
  --bs-table-active-color: #000028;
  --bs-table-hover-bg: #e8cfc0;
  --bs-table-hover-color: #000028;
  color: #000028;
  border-color: #e2cabc;
}

.table-danger {
  --bs-table-bg: #f7d3d7;
  --bs-table-striped-bg: #ebc8ce;
  --bs-table-striped-color: #000028;
  --bs-table-active-bg: #debec6;
  --bs-table-active-color: #000028;
  --bs-table-hover-bg: #e4c3ca;
  --bs-table-hover-color: #000028;
  color: #000028;
  border-color: #debec6;
}

.table-light {
  --bs-table-bg: #fff;
  --bs-table-striped-bg: #f2f2f4;
  --bs-table-striped-color: #000028;
  --bs-table-active-bg: #e6e6ea;
  --bs-table-active-color: #000028;
  --bs-table-hover-bg: #ececef;
  --bs-table-hover-color: #000028;
  color: #000028;
  border-color: #e6e6ea;
}

.table-dark {
  --bs-table-bg: #000028;
  --bs-table-striped-bg: #0d0d33;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a1a3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #131338;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1a1a3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
th {
  font-weight: 900;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.table {
  --si-table-border-spacing-x: 20px;
  --si-table-border-spacing-y: 0;
  --table-border-width: 1px;
  --si-table-border-radius: 0;
  --si-table-padding-x-xs: 0;
  border-collapse: separate;
  font-size: 16px;
  border-spacing: var(--si-table-border-spacing-x) var(--si-table-border-spacing-y);
  padding-top: 4px;
}
.table > thead > tr > th,
.table > thead > tr > td {
  border: 0;
}
.table > thead > tr > th {
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 3;
}
.table > thead > tr > th .form-floating {
  flex: 1 1 auto;
}
.table > thead > tr > th .form-floating label {
  padding-left: 0;
  padding-right: 0;
  color: #000028;
  display: inline-flex;
  align-items: center;
}
.table > thead > tr > th .form-floating > .form-control {
  background: none;
  border: 0;
  box-shadow: none;
  font-weight: bold;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  height: 50px;
}
.table > thead > tr > th .form-floating > .form-control:focus, .table > thead > tr > th .form-floating > .form-control:not(:placeholder-shown) {
  padding-bottom: 0;
  padding-top: 16px;
}
.table > thead > tr > th .form-floating > .form-control:focus ~ label, .table > thead > tr > th .form-floating > .form-control:not(:placeholder-shown) ~ label {
  transform: scale(0.6666) translateY(-6px);
}
.table > thead > tr > th .action-item-group .action {
  display: inline-flex;
  flex-flow: column;
  text-decoration: none;
  justify-content: center;
  line-height: 1;
  min-width: 26px;
  height: 26px;
}
.table > thead > tr > th .action-item-group .action:focus {
  outline: none;
  box-shadow: none;
}
.table > thead > tr > th .action-item-group .action.toggle-sort span {
  font-size: 24px;
}
.table > thead > tr > th .action-item-group .action.toggle-sort span.desc {
  margin-top: -6px;
}
.table > thead > tr > th .action-item-group .action.toggle-sort span.asc {
  margin-bottom: -6px;
}
.table > thead > tr > th .action-item-group .action.toggle-sort.desc span.asc {
  opacity: 0.5;
}
.table > thead > tr > th .action-item-group .action.toggle-sort.asc span.desc {
  opacity: 0.5;
}
.table > thead > tr > td:first-child {
  border: 0;
}
.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid transparent;
}
.table > tbody > tr {
  border-color: #b3b3be;
}
.table > tbody > tr > td {
  vertical-align: inherit;
  word-wrap: break-word;
  transition-property: border-color, box-shadow, color;
  transition-duration: 0.05s;
  transition-timing-function: cubic-bezier(0.66, 0, 0.1, 1);
  border-top-width: var(--table-border-width);
  border-bottom-width: 0;
}
.table > tbody > tr > td:first-child {
  border-top-left-radius: var(--si-table-border-radius);
  border-bottom-left-radius: var(--si-table-border-radius);
}
.table > tbody > tr > td:last-child {
  border-top-right-radius: var(--si-table-border-radius);
  border-bottom-right-radius: var(--si-table-border-radius);
}
.table > tbody > tr:first-child > td {
  border-top-width: var(--table-border-width);
}
.table > tbody > tr:last-child > td {
  border-bottom-width: var(--table-border-width);
}

.table-bordered {
  border: 0;
}

.loader {
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  transform: translate(0, 2px) scale(1, 2);
  background-color: #e0fff6;
}
.loader .loader-bar {
  animation: loading-table 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  background-color: #00ffb9;
  position: absolute;
  height: 2px;
  width: 100%;
}

@keyframes loading-table {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0.3, 1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(50%) scale(0, 1);
  }
}
@media (max-width: 767.98px) {
  .table {
    margin: 0;
    border-spacing: 0;
  }
  .table > thead > tr > th {
    padding: 0 var(--si-table-padding-x-xs);
  }
  .table > tbody > tr,
  .table > thead > tr,
  .table > tr {
    display: flex;
    flex-flow: column;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th,
  .table > tr > td,
  .table > tr > th {
    display: flex;
    width: auto !important;
  }
  .table th,
  .table td {
    border: 0 !important;
    border-radius: 0;
  }
  .table tbody > tr {
    border-top: var(--table-border-width) solid #b3b3be;
    transition-property: border-color, box-shadow;
    transition-duration: 0.05s;
    transition-timing-function: cubic-bezier(0.66, 0, 0.1, 1);
    margin-bottom: var(--si-table-border-spacing-y);
  }
  .table tbody > tr td {
    padding-left: var(--si-table-padding-x-xs);
    padding-right: var(--si-table-padding-x-xs);
  }
  .table tbody > tr td:first-child {
    border-top-right-radius: var(--si-table-border-radius);
    border-bottom-left-radius: 0;
  }
  .table tbody > tr td:last-child {
    border-bottom-left-radius: var(--si-table-border-radius);
    border-top-right-radius: 0;
  }
  .table tbody > tr:hover {
    border-color: #b3b3be;
  }
  .table tbody > tr:hover + tr {
    border-top-color: #196269;
  }
  .table tbody > tr:last-child {
    border-bottom: var(--table-border-width) solid #b3b3be;
    margin-bottom: 0;
  }
}
.table-hover tbody tr:hover td {
  border-color: #b3b3be;
}
.table-hover tbody tr:hover + tr td {
  border-top-color: #196269;
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.40625rem;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.984375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #7d8099;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4c4c68;
  background-color: #ebf7f8;
  background-clip: padding-box;
  border: 0 solid #4c4c68;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #4c4c68;
  background-color: #ebf7f8;
  border-color: #00d7a0;
  outline: 0;
  box-shadow: initial;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #ebf7f8;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 1rem;
  margin: -0.375rem -1rem;
  margin-inline-end: 1rem;
  color: #4c4c68;
  background-color: #00ffb9;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s cubic-bezier(0.66, 0, 0.1, 1), background-color 0.15s cubic-bezier(0.66, 0, 0.1, 1), border-color 0.15s cubic-bezier(0.66, 0, 0.1, 1), box-shadow 0.15s cubic-bezier(0.66, 0, 0.1, 1);
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #00f2b0;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 1rem;
  margin: -0.375rem -1rem;
  margin-inline-end: 1rem;
  color: #4c4c68;
  background-color: #00ffb9;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s cubic-bezier(0.66, 0, 0.1, 1), background-color 0.15s cubic-bezier(0.66, 0, 0.1, 1), border-color 0.15s cubic-bezier(0.66, 0, 0.1, 1), box-shadow 0.15s cubic-bezier(0.66, 0, 0.1, 1);
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #00f2b0;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #000028;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  border-radius: 0;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.40625rem;
  border-radius: 0;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: 57px;
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 3rem 0.375rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4c4c68;
  background-color: #ebf7f8;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 0 solid #4c4c68;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #00d7a0;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 138, 166, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4c4c68;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.984375rem;
  border-radius: 0;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.40625rem;
  border-radius: 0;
}

.form-check {
  display: block;
  min-height: 1.6875rem;
  padding-left: 0;
  margin-bottom: 2px;
}
.form-check .form-check-input {
  float: left;
  margin-left: 0;
}

.form-check-input {
  width: 1.3636em;
  height: 1.3636em;
  margin-top: 0.0682em;
  vertical-align: top;
  background-color: #e0f1f4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #4c4c68;
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: none;
}
.form-check-input:focus {
  border-color: #4c4c68;
  outline: 0;
  box-shadow: initial;
}
.form-check-input:checked {
  background-color: #e0f1f4;
  border-color: #4c4c68;
}
.form-check-input:checked[type=checkbox] {
  background-image: initial;
}
.form-check-input:checked[type=radio] {
  background-image: initial;
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #e0f1f4;
  border-color: #4c4c68;
  background-image: initial;
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 1;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 1;
}

.form-switch {
  padding-left: 0;
}
.form-switch .form-check-input {
  width: 53px;
  margin-left: 0;
  background-image: initial;
  background-position: left center;
  border-radius: 53px;
  transition: background-color 0.2s cubic-bezier(0.66, 0, 0.1, 1);
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: initial;
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: initial;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, initial;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, initial;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #008aa6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3dce4;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #008aa6;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3dce4;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: 3.5rem;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 0 solid transparent;
  transform-origin: 0 0;
  transition: transform 0.2s cubic-bezier(0.66, 0, 0.1, 1), color 0.15s cubic-bezier(0.66, 0, 0.1, 1);
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 23px;
  padding-bottom: 7px;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 23px;
  padding-bottom: 7px;
}
.form-floating > .form-select {
  padding-top: 23px;
  padding-bottom: 7px;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.6666) translateY(-6px) translateX(8px);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 1;
  transform: scale(0.6666) translateY(-6px) translateX(8px);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000028;
  text-align: center;
  white-space: nowrap;
  background-color: #00ffb9;
  border: 0 solid #4c4c68;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.40625rem;
  border-radius: 0;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  border-radius: 0;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 5px;
  font-size: 0.75rem;
  color: #01893a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 8px 16px;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #01893a;
  border-radius: 0;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #01893a;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #01893a;
  box-shadow: 0 0 0 0 rgba(1, 137, 58, 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #01893a;
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #01893a;
  box-shadow: 0 0 0 0 rgba(1, 137, 58, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #01893a;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #01893a;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(1, 137, 58, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #01893a;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 5px;
  font-size: 0.75rem;
  color: #ef0137;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 8px 16px;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #ef0137;
  border-radius: 0;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.ng-invalid.ng-touched ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.ng-invalid.ng-touched ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .form-control.ng-invalid.ng-touched {
  border-color: #ef0137;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .form-control.ng-invalid.ng-touched:focus {
  border-color: #ef0137;
  box-shadow: 0 0 0 0 rgba(239, 1, 55, 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid, .form-select.ng-invalid.ng-touched {
  border-color: #ef0137;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus, .form-select.ng-invalid.ng-touched:focus {
  border-color: #ef0137;
  box-shadow: 0 0 0 0 rgba(239, 1, 55, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid, .form-check-input.ng-invalid.ng-touched {
  border-color: #ef0137;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked, .form-check-input.ng-invalid.ng-touched:checked {
  background-color: #ef0137;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus, .form-check-input.ng-invalid.ng-touched:focus {
  box-shadow: 0 0 0 0 rgba(239, 1, 55, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .form-check-input.ng-invalid.ng-touched ~ .form-check-label {
  color: #ef0137;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .input-group .form-control.ng-invalid.ng-touched,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid,
.input-group .form-select.ng-invalid.ng-touched {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .input-group .form-control.ng-invalid.ng-touched:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus,
.input-group .form-select.ng-invalid.ng-touched:focus {
  z-index: 3;
}

.form-check {
  padding-left: 0;
}

.form-check-inline .form-check-input {
  position: relative;
}

.form-floating {
  min-width: 0;
}
.form-floating > label {
  z-index: 3;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.input-group .btn {
  line-height: 1;
  padding: 0;
  min-width: 57px;
  border-radius: 0;
}

.form-feedback {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-control,
si-dropdown {
  border-bottom-width: 1px;
}
.form-control:focus-visible, .form-control.focus,
si-dropdown:focus-visible,
si-dropdown.focus {
  outline: 2px solid #199fff;
  outline-offset: 2px;
}
.form-control:disabled, .form-control.disabled,
si-dropdown:disabled,
si-dropdown.disabled {
  color: #b3b3be;
  border-bottom-width: 0;
}
.form-control[readonly],
si-dropdown[readonly] {
  background-color: transparent;
  color: #b3b3be;
  border-bottom-width: 0;
}
.form-control:not(:disabled):not(.disabled):hover, .form-control:not(:disabled):not(.disabled):active, .form-control:not(:disabled):not(.disabled).active, .form-control:not(:disabled):not(.disabled).hover,
si-dropdown:not(:disabled):not(.disabled):hover,
si-dropdown:not(:disabled):not(.disabled):active,
si-dropdown:not(:disabled):not(.disabled).active,
si-dropdown:not(:disabled):not(.disabled).hover {
  background-color: #d1fff2;
  border-color: #00d7a0;
}
.form-control:not(:disabled):not(.disabled):hover ~ label, .form-control:not(:disabled):not(.disabled):active ~ label, .form-control:not(:disabled):not(.disabled).active ~ label, .form-control:not(:disabled):not(.disabled).hover ~ label,
si-dropdown:not(:disabled):not(.disabled):hover ~ label,
si-dropdown:not(:disabled):not(.disabled):active ~ label,
si-dropdown:not(:disabled):not(.disabled).active ~ label,
si-dropdown:not(:disabled):not(.disabled).hover ~ label {
  color: #000028;
}

.form-floating .form-control ~ .si-label-overlap-fix {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 16px);
  height: 22px;
  border-radius: 0;
  background-color: #ebf7f8;
  z-index: 2;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0.15s ease-in-out;
  cursor: text;
}
.form-floating .form-control:hover ~ .si-label-overlap-fix {
  background-color: #d1fff2 !important;
}
.form-floating .form-control.is-invalid ~ .si-label-overlap-fix, .form-floating .form-control.ng-touched.ng-invalid ~ .si-label-overlap-fix {
  background-color: #fcccd7;
}

.button-clear {
  color: #000028;
  opacity: 0.7;
}

.form-check,
.form-control ~ label,
si-dropdown ~ label {
  color: #4c4c68;
}

.form-floating > .si-dropdown-has-value .form-control {
  padding-top: 23px;
  padding-bottom: 7px;
}
.form-floating > .si-dropdown-has-value ~ label {
  transform: scale(0.6666) translateY(-6px) translateX(8px);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-control.ng-invalid.ng-touched,
si-dropdown.is-invalid,
si-dropdown.ng-invalid.ng-touched {
  background: #fcccd7;
  border-top-color: #fcccd7;
}
.was-validated .form-control:invalid ~ label,
.form-control.is-invalid ~ label,
.form-control.ng-invalid.ng-touched ~ label,
si-dropdown.is-invalid ~ label,
si-dropdown.ng-invalid.ng-touched ~ label {
  color: #ef0137;
}

.form-check-input {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  margin-top: calc(0.0682em + 1px);
  color: inherit;
}
.form-check-input:checked ~ .form-check-label {
  color: #000028;
}
.form-check-input:hover {
  color: #005159 !important;
  border-color: #005159 !important;
  background-color: #c2ffee !important;
}
.form-check-input:focus-visible {
  background-color: #c2ffee !important;
}
.form-check-input:disabled, .form-check-input[disabled] {
  color: #7d8099 !important;
  border-color: #7d8099 !important;
  background-color: transparent !important;
}
.form-check-input:disabled::after, .form-check-input[disabled]::after {
  color: inherit;
}
.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  color: #7d8099;
}
.form-check-input.ng-invalid.ng-touched {
  border-color: #ef0137;
  background-color: #fcccd7;
}

.si-input-inline-container {
  z-index: 5;
  position: absolute;
  right: 1rem;
  top: 0;
  height: 57px;
  display: flex;
  align-items: center;
}
.si-input-inline-container .si-input-inline-btn {
  color: inherit;
  background: transparent;
  border: 0;
  font-size: 0.8em;
}

.form-floating .si-input-inline-container {
  height: 3.5rem;
}

input[type=checkbox]:checked::after {
  content: "\ea30";
  font-family: "simpl-siemens-brand-icons";
  position: absolute;
  font-size: 1em;
  top: -2px;
  left: 1px;
}
input[type=checkbox]:indeterminate::after {
  content: "\eaf1";
  font-family: "simpl-siemens-brand-icons";
  position: absolute;
  font-size: 1em;
  top: -2px;
  left: 2px;
}

input[type=radio]:checked::after {
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 5px;
  background: #4c4c68;
  text-shadow: none;
  font-size: 32px;
}
input[type=radio]:hover::after {
  background: #005159;
}

.form-switch .form-check-label {
  transition: color 0.2s cubic-bezier(0.66, 0, 0.1, 1);
}
.form-switch .form-check-input {
  opacity: 1;
  border: 0 !important;
  height: 1.25rem;
  background-color: #ccccd4;
}
.form-switch .form-check-input::after {
  display: none;
}
.form-switch .form-check-input::before {
  display: block;
  position: absolute;
  content: " ";
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #66667e;
  top: -4px;
  right: 25px;
  transition: right 0.2s cubic-bezier(0.66, 0, 0.1, 1), background-color 0.2s cubic-bezier(0.66, 0, 0.1, 1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 1px 1px rgba(0, 0, 0, 0.11);
}
.form-switch .form-check-input:hover, .form-switch .form-check-input:focus-visible {
  background-color: #9999a9 !important;
}
.form-switch .form-check-input:hover::before, .form-switch .form-check-input:focus-visible::before {
  background-color: #4c4c68;
}
.form-switch .form-check-input:checked {
  background-color: #00ffb9 !important;
}
.form-switch .form-check-input:checked::before {
  background-color: #196269;
}
.form-switch .form-check-input:checked:hover, .form-switch .form-check-input:checked:focus-visible {
  background-color: #62eec7 !important;
}
.form-switch .form-check-input:checked:hover::before, .form-switch .form-check-input:checked:focus-visible::before {
  background-color: #005159;
}
.form-switch .form-check-input:checked::before {
  right: 0;
}
.form-switch .form-check-input:disabled {
  background-color: #ebebee !important;
}
.form-switch .form-check-input:disabled::before {
  box-shadow: none;
  background-color: #ccccd4 !important;
}

.btn {
  display: inline-block;
  font-weight: middle;
  line-height: 1.5;
  color: #000028;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 15px 50px;
  font-size: 1.125rem;
  border-radius: 0;
  transition: color 0.15s cubic-bezier(0.66, 0, 0.1, 1), background-color 0.15s cubic-bezier(0.66, 0, 0.1, 1), border-color 0.15s cubic-bezier(0.66, 0, 0.1, 1), box-shadow 0.15s cubic-bezier(0.66, 0, 0.1, 1);
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000028;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: initial;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #008aa6;
  border-color: #008aa6;
}
.btn-primary:hover {
  color: #fff;
  background-color: #00758d;
  border-color: #006e85;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #00758d;
  border-color: #006e85;
  box-shadow: 0 0 0 0 rgba(38, 156, 179, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #006e85;
  border-color: #00687d;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 156, 179, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #008aa6;
  border-color: #008aa6;
}

.btn-secondary {
  color: #000028;
  background-color: #0cc;
  border-color: #0cc;
}
.btn-secondary:hover {
  color: #000028;
  background-color: #26d4d4;
  border-color: #1ad1d1;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000028;
  background-color: #26d4d4;
  border-color: #1ad1d1;
  box-shadow: 0 0 0 0 rgba(0, 173, 179, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000028;
  background-color: #33d6d6;
  border-color: #1ad1d1;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 173, 179, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000028;
  background-color: #0cc;
  border-color: #0cc;
}

.btn-success {
  color: #fff;
  background-color: #01893a;
  border-color: #01893a;
}
.btn-success:hover {
  color: #fff;
  background-color: #017431;
  border-color: #016e2e;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #017431;
  border-color: #016e2e;
  box-shadow: 0 0 0 0 rgba(39, 155, 88, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #016e2e;
  border-color: #01672c;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(39, 155, 88, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #01893a;
  border-color: #01893a;
}

.btn-info {
  color: #fff;
  background-color: #007eb1;
  border-color: #007eb1;
}
.btn-info:hover {
  color: #fff;
  background-color: #006b96;
  border-color: #00658e;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #006b96;
  border-color: #00658e;
  box-shadow: 0 0 0 0 rgba(38, 145, 189, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00658e;
  border-color: #005f85;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 145, 189, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #007eb1;
  border-color: #007eb1;
}

.btn-warning {
  color: #fff;
  background-color: #e96401;
  border-color: #e96401;
}
.btn-warning:hover {
  color: #fff;
  background-color: #c65501;
  border-color: #ba5001;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #fff;
  background-color: #c65501;
  border-color: #ba5001;
  box-shadow: 0 0 0 0 rgba(236, 123, 39, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ba5001;
  border-color: #af4b01;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(236, 123, 39, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #fff;
  background-color: #e96401;
  border-color: #e96401;
}

.btn-danger {
  color: #fff;
  background-color: #d72339;
  border-color: #d72339;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b71e30;
  border-color: #ac1c2e;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #b71e30;
  border-color: #ac1c2e;
  box-shadow: 0 0 0 0 rgba(221, 68, 87, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ac1c2e;
  border-color: #a11a2b;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(221, 68, 87, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #d72339;
  border-color: #d72339;
}

.btn-light {
  color: #000028;
  background-color: #fff;
  border-color: #fff;
}
.btn-light:hover {
  color: #000028;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000028;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0 rgba(217, 217, 223, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000028;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 217, 223, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000028;
  background-color: #fff;
  border-color: #fff;
}

.btn-dark {
  color: #fff;
  background-color: #000028;
  border-color: #000028;
}
.btn-dark:hover {
  color: #fff;
  background-color: #000022;
  border-color: #000020;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #000022;
  border-color: #000020;
  box-shadow: 0 0 0 0 rgba(38, 38, 72, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #000020;
  border-color: #00001e;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 38, 72, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #000028;
  border-color: #000028;
}

.btn-inactive {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.btn-inactive:hover {
  color: #fff;
  background-color: #76767f;
  border-color: #6f6f77;
}
.btn-check:focus + .btn-inactive, .btn-inactive:focus {
  color: #fff;
  background-color: #76767f;
  border-color: #6f6f77;
  box-shadow: 0 0 0 0 rgba(156, 156, 165, 0.5);
}
.btn-check:checked + .btn-inactive, .btn-check:active + .btn-inactive, .btn-inactive:active, .btn-inactive.active, .show > .btn-inactive.dropdown-toggle {
  color: #fff;
  background-color: #6f6f77;
  border-color: #686870;
}
.btn-check:checked + .btn-inactive:focus, .btn-check:active + .btn-inactive:focus, .btn-inactive:active:focus, .btn-inactive.active:focus, .show > .btn-inactive.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(156, 156, 165, 0.5);
}
.btn-inactive:disabled, .btn-inactive.disabled {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}

.btn-outline-primary {
  color: #008aa6;
  border-color: #008aa6;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #008aa6;
  border-color: #008aa6;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(0, 138, 166, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #008aa6;
  border-color: #008aa6;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 138, 166, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #008aa6;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #0cc;
  border-color: #0cc;
}
.btn-outline-secondary:hover {
  color: #000028;
  background-color: #0cc;
  border-color: #0cc;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(0, 204, 204, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000028;
  background-color: #0cc;
  border-color: #0cc;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 204, 204, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #0cc;
  background-color: transparent;
}

.btn-outline-success {
  color: #01893a;
  border-color: #01893a;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #01893a;
  border-color: #01893a;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(1, 137, 58, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #01893a;
  border-color: #01893a;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(1, 137, 58, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #01893a;
  background-color: transparent;
}

.btn-outline-info {
  color: #007eb1;
  border-color: #007eb1;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #007eb1;
  border-color: #007eb1;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(0, 126, 177, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #007eb1;
  border-color: #007eb1;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 126, 177, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #007eb1;
  background-color: transparent;
}

.btn-outline-warning {
  color: #e96401;
  border-color: #e96401;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #e96401;
  border-color: #e96401;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(233, 100, 1, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #fff;
  background-color: #e96401;
  border-color: #e96401;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(233, 100, 1, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #e96401;
  background-color: transparent;
}

.btn-outline-danger {
  color: #d72339;
  border-color: #d72339;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #d72339;
  border-color: #d72339;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(215, 35, 57, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #d72339;
  border-color: #d72339;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(215, 35, 57, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #d72339;
  background-color: transparent;
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}
.btn-outline-light:hover {
  color: #000028;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000028;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-dark {
  color: #000028;
  border-color: #000028;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #000028;
  border-color: #000028;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 40, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #000028;
  border-color: #000028;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 40, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #000028;
  background-color: transparent;
}

.btn-outline-inactive {
  color: #8b8b95;
  border-color: #8b8b95;
}
.btn-outline-inactive:hover {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.btn-check:focus + .btn-outline-inactive, .btn-outline-inactive:focus {
  box-shadow: 0 0 0 0 rgba(139, 139, 149, 0.5);
}
.btn-check:checked + .btn-outline-inactive, .btn-check:active + .btn-outline-inactive, .btn-outline-inactive:active, .btn-outline-inactive.active, .btn-outline-inactive.dropdown-toggle.show {
  color: #fff;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.btn-check:checked + .btn-outline-inactive:focus, .btn-check:active + .btn-outline-inactive:focus, .btn-outline-inactive:active:focus, .btn-outline-inactive.active:focus, .btn-outline-inactive.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(139, 139, 149, 0.5);
}
.btn-outline-inactive:disabled, .btn-outline-inactive.disabled {
  color: #8b8b95;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #007993;
  text-decoration: none;
}
.btn-link:hover {
  color: #005159;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.40625rem;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
  border-radius: 0;
}

.btn-primary:hover, .btn-primary:focus {
  background: #00646e;
  border-color: #00646e;
}

.btn-outline-primary:not(:disabled):not(.disabled):hover, .btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #00646e;
  border-color: #00646e;
}

.btn:focus {
  /* stylelint-disable-next-line declaration-no-important */
  outline: 2px solid #199fff !important;
  outline-offset: 2px;
}
.btn.btn-link {
  padding: 0;
}
.btn.disabled, .btn[disabled] {
  pointer-events: none;
}

.btn-free, .btn-default {
  color: #008aa6;
}
.btn-free:hover, .btn-default:hover, .btn-free:focus, .btn-default:focus {
  color: #00646e;
  background-color: #c2ffee;
}
.btn-free:active, .btn-default:active {
  color: #005673;
  background-color: #c2ffee;
}
.btn-free.disabled, .disabled.btn-default, .btn-free[disabled], [disabled].btn-default {
  color: #008aa6;
  pointer-events: none;
}

.btn-default {
  border-color: #008aa6;
}
.btn-default:hover, .btn-default:focus {
  border-color: #00646e;
}
.btn-default:active {
  border-color: #005673;
}

.btn-hero {
  /* stylelint-disable-next-line declaration-no-important */
  color: #000028 !important;
  background: linear-gradient(to right, #00e6dc 0%, #00ffb9 100%);
  background-color: #00ffb9;
}
.btn-hero:hover {
  background: #00ffb9;
}
.btn-hero:active {
  background: #00e6dc;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0 0;
  margin: 0;
  font-size: 1.125rem;
  color: #000028;
  text-align: left;
  list-style: none;
  background-color: #d1fff2;
  background-clip: padding-box;
  border: 0 solid #00d7a0;
  border-radius: 0;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #00d7a0;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #000028;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #000028;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #000028;
  text-decoration: none;
  background-color: #62eec7;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #9999a9;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 10px 16px;
  margin-bottom: 0;
  font-size: 0.984375rem;
  color: #000028;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #000028;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: #00d7a0;
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #000028;
  background-color: #62eec7;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: #00d7a0;
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.dropdown-toggle {
  height: 57px;
  cursor: pointer;
}
.dropdown-toggle.active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-floating .dropdown-toggle {
  height: 3.5rem;
  line-height: 1;
}

.dropdown-menu {
  top: 57px;
  left: 0;
  border-top-width: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-menu > li.selected {
  background-color: #62eec7;
}
.dropdown-menu .dropdown-item {
  padding: 5px 29px 5px 18px;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
  background-color: #62eec7;
  cursor: pointer;
  padding-right: 15px;
}
.dropdown-menu.multi > li > a::before {
  content: "\ea30";
  font-family: "simpl-siemens-brand-icons";
  padding: 0 0 0 1px !important;
  font-size: 14px;
  color: #e0f1f4;
  background-color: #e0f1f4;
  border: 2px solid #4c4c68;
  width: 21px;
  display: inline-flex;
  height: 21px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.dropdown-menu.multi > li > a.checked::before {
  color: #4c4c68;
}
.dropdown-menu.multi > li > a.disabled::before {
  color: transparent;
  border-color: #7d8099;
  background-color: transparent;
}

.form-floating .dropdown-menu {
  top: 3.5rem;
}

.si-input-inline-btn.si-dropdown-toggle-icon {
  font-size: 1em;
}

.form-control.dropdown-toggle {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.form-control.dropdown-toggle[disabled] {
  background-color: #ebf7f8;
  color: #b3b3be;
}
.form-control.dropdown-toggle[disabled] + .icon-dropdown {
  color: #7d8099;
}
.form-control.dropdown-toggle.focus ~ * .si-dropdown-toggle-icon > * {
  transform: rotate(-180deg);
  transform-origin: center;
}
.form-control.dropdown-toggle > .dropdown-menu {
  width: 100%;
}

.dropdown-header {
  background: #00ffb9;
  border-bottom: 0;
}

.si-dropdown-menu-standalone {
  border-top: 1px solid #000028;
  margin-top: 23px;
  color: #000028;
  padding: 20px;
  top: 0;
  width: 280px !important;
}

.si-dropdown-menu-standalone-align-right {
  right: 0;
}
.si-dropdown-menu-standalone-align-right .si-triangle-top {
  left: auto;
  right: 15px;
}

.si-dropdown-border-loader {
  position: absolute;
  height: 1px;
  background: #d1fff2;
  top: -1px;
  right: 0;
  width: 0;
}

.si-triangle-top {
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #000028;
  position: absolute;
  top: -13px;
  left: 12px;
}
.si-triangle-top::after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 11px solid #d1fff2;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  position: absolute;
  top: 2px;
  left: -11px;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 37.5px;
  padding-left: 37.5px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0 0.9375rem;
  color: #000028;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #005159;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000028;
  background-color: transparent;
  border-color: #005159;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #008aa6;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.nav:hover {
  color: inherit;
}

.nav-tabs {
  border: 0;
}
@media (max-width: 575.98px) {
  .nav-tabs > .nav-link {
    flex: 1 100%;
  }
}
.nav-tabs .nav-link {
  margin-bottom: 0;
  padding: 34px 30px 34px 30px;
  color: #000028;
  border: 0;
  border-bottom: #ccccd4 2px solid;
  cursor: pointer;
  text-align: center;
  flex: 1;
  font-weight: bold;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  background: #c5ffef;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.tab-pane {
  padding-top: 30px;
  padding-bottom: 30px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.625rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.625rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.625rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-group > .card {
  margin-bottom: 0.9375rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card .card-title {
  position: relative;
  padding-left: 15px;
}
.card .card-title::before {
  content: " ";
  position: absolute;
  top: 7px;
  bottom: 7px;
  left: 0;
  width: 3px;
  background: linear-gradient(to bottom, #00e6dc 0%, #00ffb9 100%);
}

.card-img-overlay {
  top: auto;
}

.accordion > .card, accordion-group > .card {
  background-color: transparent;
  overflow: visible;
  border-bottom: 0;
  border-top: 1px solid #000028;
  transition-property: border-top-color, background-color;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.66, 0, 0.1, 1);
}
.accordion > .card .card-header, accordion-group > .card .card-header {
  transition-property: background-color;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.66, 0, 0.1, 1);
}
.accordion > .card:hover, accordion-group > .card:hover {
  z-index: 4;
  border-top-color: #000028;
  border-bottom: 0 solid #000028;
  margin-bottom: 0;
}
.accordion > .card:hover .card-header, accordion-group > .card:hover .card-header {
  background-color: #ebf7f8;
}
.accordion > .card:hover + .accordion, accordion-group > .card:hover + .accordion, .accordion > .card:hover + accordion-group, accordion-group > .card:hover + accordion-group {
  border-top: 0;
}
.accordion > .card > .card-header, accordion-group > .card > .card-header {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  font-weight: 900;
  padding: 1.25rem 0.9375rem 1.25rem 20px;
  cursor: pointer;
  border-bottom: 0;
}
.accordion > .card > .card-header::after, accordion-group > .card > .card-header::after {
  content: "\eb20";
  font-family: "simpl-siemens-brand-icons";
  display: flex;
  align-items: center;
}
.accordion > .card > .card-header .card-title, accordion-group > .card > .card-header .card-title {
  display: inline-block;
  margin: 0;
  position: relative;
  font-size: 20px;
  width: 100%;
  text-align: left;
  box-shadow: none;
  line-height: 26px;
  opacity: 0.8;
}
.accordion > .card > .card-header .card-title:hover, accordion-group > .card > .card-header .card-title:hover, .accordion > .card > .card-header .card-title:active, accordion-group > .card > .card-header .card-title:active {
  opacity: 1;
}
.accordion > .card > .card-header .btn-link, accordion-group > .card > .card-header .btn-link {
  font-weight: 900;
  font-size: 20px;
  color: #000028;
  text-decoration: none;
}
.accordion > .card > .card-header.collapsed .card-title::after, accordion-group > .card > .card-header.collapsed .card-title::after {
  transform: rotate(0deg);
}
.accordion > .card > .card-body, accordion-group > .card > .card-body {
  padding: 1.25rem 0 2.5rem;
}
.accordion:last-child > .card, accordion-group:last-child > .card {
  border-bottom: 0 solid 1px solid #000028;
}
.accordion:last-child > .card:hover, accordion-group:last-child > .card:hover {
  border-bottom: 0 solid #000028;
}
.accordion.open .card-header::after, accordion-group.open .card-header::after, .accordion.panel-open .card-header::after, accordion-group.panel-open .card-header::after {
  content: "\eaf1";
  font-family: "simpl-siemens-brand-icons";
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #005159;
}

.breadcrumb-item {
  display: inline-flex;
  align-items: center;
}
.breadcrumb-item > a {
  display: inline-flex;
  align-items: center;
  color: #008aa6;
}
.breadcrumb-item > a:hover {
  color: #005159;
  text-decoration: none;
}
.breadcrumb-item:last-child > a {
  color: #005159;
  font-weight: inherit;
}
.breadcrumb-item.active > a {
  font-weight: inherit;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "\ea0e";
  font-family: "simpl-siemens-brand-icons";
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0cc;
  background-color: transparent;
  border: 0 solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #005159;
  background-color: #c2ffee;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #005159;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: none;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #005159;
  background-color: #62eec7;
  border-color: #62eec7;
}
.page-item.disabled .page-link {
  color: #7d8099;
  pointer-events: none;
  background-color: transparent;
  border-color: #dee2e6;
}

.page-link {
  padding: 2px 10px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.40625rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.984375rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-link {
  min-width: 32px;
  height: 32px;
}

.page-item.active .page-link {
  font-weight: 900;
}

.pagination {
  margin-bottom: 16px;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1.375rem 1.375rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.125rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.71875rem 1.375rem;
}

.alert-primary {
  color: #99d0db;
  background-color: #008aa6;
  border-color: #008aa6;
}
.alert-primary .alert-link {
  color: #7aa6af;
}

.alert-secondary {
  color: #00526a;
  background-color: #00cccc;
  border-color: #00cccc;
}
.alert-secondary .alert-link {
  color: #004255;
}

.alert-success {
  color: #99d0b0;
  background-color: #01893a;
  border-color: #01893a;
}
.alert-success .alert-link {
  color: #7aa68d;
}

.alert-info {
  color: #99cbe0;
  background-color: #007eb1;
  border-color: #007eb1;
}
.alert-info .alert-link {
  color: #7aa2b3;
}

.alert-warning {
  color: #f6c199;
  background-color: #e96401;
  border-color: #e96401;
}
.alert-warning .alert-link {
  color: #c59a7a;
}

.alert-danger {
  color: #efa7b0;
  background-color: #d72339;
  border-color: #d72339;
}
.alert-danger .alert-link {
  color: #bf868d;
}

.alert-light {
  color: #66667e;
  background-color: white;
  border-color: white;
}
.alert-light .alert-link {
  color: #525265;
}

.alert-dark {
  color: #9999a9;
  background-color: #000028;
  border-color: #000028;
}
.alert-dark .alert-link {
  color: #7a7a87;
}

.alert-inactive {
  color: #d1d1d5;
  background-color: #8b8b95;
  border-color: #8b8b95;
}
.alert-inactive .alert-link {
  color: #a7a7aa;
}

.alert {
  color: #fff;
}

.alert-dismissible .close > * {
  display: none;
}
.alert-dismissible .close:hover {
  color: inherit;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.84375rem;
  background-color: #e9ecef;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #008aa6;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #000028;
  text-decoration: none;
  background-color: #d1fff2;
}
.list-group-item-action:active {
  color: #000028;
  background-color: #c2ffee;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 24px 20px;
  color: #000028;
  background-color: #fff;
  border: 1px solid #b3b3be;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #d1fff2;
  border-color: #196269;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #005364;
  background-color: #cce8ed;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #005364;
  background-color: #b8d1d5;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #005364;
  border-color: #005364;
}

.list-group-item-secondary {
  color: #007a7a;
  background-color: #ccf5f5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #007a7a;
  background-color: #b8dddd;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #007a7a;
  border-color: #007a7a;
}

.list-group-item-success {
  color: #015223;
  background-color: #cce7d8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #015223;
  background-color: #b8d0c2;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #015223;
  border-color: #015223;
}

.list-group-item-info {
  color: #004c6a;
  background-color: #cce5ef;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #004c6a;
  background-color: #b8ced7;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #004c6a;
  border-color: #004c6a;
}

.list-group-item-warning {
  color: #8c3c01;
  background-color: #fbe0cc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #8c3c01;
  background-color: #e2cab8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #8c3c01;
  border-color: #8c3c01;
}

.list-group-item-danger {
  color: #811522;
  background-color: #f7d3d7;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #811522;
  background-color: #debec2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #811522;
  border-color: #811522;
}

.list-group-item-light {
  color: #66667e;
  background-color: white;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #66667e;
  background-color: #e6e6e6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #66667e;
  border-color: #66667e;
}

.list-group-item-dark {
  color: #000018;
  background-color: #ccccd4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #000018;
  background-color: #b8b8bf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #000018;
  border-color: #000018;
}

.list-group-item-inactive {
  color: #535359;
  background-color: #e8e8ea;
}
.list-group-item-inactive.list-group-item-action:hover, .list-group-item-inactive.list-group-item-action:focus {
  color: #535359;
  background-color: #d1d1d3;
}
.list-group-item-inactive.list-group-item-action.active {
  color: #fff;
  background-color: #535359;
  border-color: #535359;
}

.list-group-item {
  transition: background-color 0.2s cubic-bezier(0.66, 0, 0.1, 1);
  border-style: solid none;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000028;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000028'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000028;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: initial;
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.btn-close {
  display: inline-flex;
  align-items: center;
  background: transparent;
}
.btn-close::after {
  content: "\ea39";
  font-family: "simpl-siemens-brand-icons";
}

.toast {
  width: auto;
  max-width: 100%;
  font-size: 1.125rem;
  color: #fff;
  pointer-events: auto;
  background-color: #001f39;
  background-clip: padding-box;
  border: 3px solid #001f39;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.9375rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 12px 12px;
  color: #fff;
  background-color: #001f39;
  background-clip: padding-box;
  border-bottom: 3px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.toast-header .btn-close {
  margin-right: -6px;
  margin-left: 12px;
}

.toast-body {
  padding: 12px;
  word-wrap: break-word;
}

.toast-header {
  gap: 15px;
}

.toast-container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 2000;
  max-width: calc(100% - 30px);
}
.toast-container .btn-close {
  color: #fff;
}
.toast-container .toast-content {
  width: 100%;
}

.si-toast-no-body .toast-header {
  border-radius: inherit;
  border-bottom: 0;
}

.si-toast-timeout {
  border-bottom: 3px solid #737389;
}

.si-toast-timeout-bar {
  position: absolute;
  bottom: -3px;
  left: -3px;
  right: -3px;
  height: 3px;
  background-color: #0cc;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transform-origin: right;
  transform: scaleX(0);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000028;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #f3f3f0;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 0 solid #d1fff2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .btn-close {
  padding: 12px 16px;
  margin: -12px -16px -12px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 8px 32px 8px;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 32px 8px-0.25rem;
  border-top: 0 solid #d1fff2;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.modal-open {
  padding-right: 0 !important;
}

.modal-content {
  min-height: 270px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.modal-footer {
  padding: 8px 32px 24px;
}
.modal-footer .btn {
  font-weight: bold;
}

.modal-header {
  padding-bottom: 8px;
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000028;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000028;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000028;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000028;
}

.tooltip-inner {
  max-width: 200px;
  padding: 8px 16px;
  color: #fff;
  text-align: center;
  background-color: #000028;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.984375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #000028;
}

.popover {
  border: 0;
  border-radius: 0;
  color: #eeeeee;
  text-align: center;
}

.popover-header {
  color: #007993;
  background-color: initial;
  border-bottom: 0;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
/*!
 * Bootstrap Utilities v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #008aa6;
}
.link-primary:hover, .link-primary:focus {
  color: #006e85;
}

.link-secondary {
  color: #0cc;
}
.link-secondary:hover, .link-secondary:focus {
  color: #33d6d6;
}

.link-success {
  color: #01893a;
}
.link-success:hover, .link-success:focus {
  color: #016e2e;
}

.link-info {
  color: #007eb1;
}
.link-info:hover, .link-info:focus {
  color: #00658e;
}

.link-warning {
  color: #e96401;
}
.link-warning:hover, .link-warning:focus {
  color: #ba5001;
}

.link-danger {
  color: #d72339;
}
.link-danger:hover, .link-danger:focus {
  color: #ac1c2e;
}

.link-light {
  color: #fff;
}
.link-light:hover, .link-light:focus {
  color: white;
}

.link-dark {
  color: #000028;
}
.link-dark:hover, .link-dark:focus {
  color: #000020;
}

.link-inactive {
  color: #8b8b95;
}
.link-inactive:hover, .link-inactive:focus {
  color: #6f6f77;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #d1fff2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #d1fff2 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #d1fff2 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d1fff2 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #d1fff2 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #008aa6 !important;
}

.border-secondary {
  border-color: #0cc !important;
}

.border-success {
  border-color: #01893a !important;
}

.border-info {
  border-color: #007eb1 !important;
}

.border-warning {
  border-color: #e96401 !important;
}

.border-danger {
  border-color: #d72339 !important;
}

.border-light {
  border-color: #fff !important;
}

.border-dark {
  border-color: #000028 !important;
}

.border-inactive {
  border-color: #8b8b95 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.5rem !important;
}

.gap-6 {
  gap: 2rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-6 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-6 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mt-6 {
  margin-top: 2rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.5rem !important;
}

.me-6 {
  margin-right: 2rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 2rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.5rem !important;
}

.ms-6 {
  margin-left: 2rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-6 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-6 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pt-6 {
  padding-top: 2rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.5rem !important;
}

.pe-6 {
  padding-right: 2rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 2rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.5rem !important;
}

.ps-6 {
  padding-left: 2rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: 3.75rem !important;
}

.fs-2 {
  font-size: 2.5rem !important;
}

.fs-3 {
  font-size: 2.25rem !important;
}

.fs-4 {
  font-size: 2rem !important;
}

.fs-5 {
  font-size: 1.5rem !important;
}

.fs-6 {
  font-size: 1.25rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-inactive {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-inactive-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #7d8099 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-inactive {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-inactive-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0 !important;
}

.rounded-2 {
  border-radius: 0 !important;
}

.rounded-3 {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.text-reverse-theme-success {
  --bs-text-opacity: 1;
  color: #65c728 !important;
}

.text-reverse-theme-info {
  --bs-text-opacity: 1;
  color: #00bedc !important;
}

.text-reverse-theme-warning {
  --bs-text-opacity: 1;
  color: #ffd732 !important;
}

.text-reverse-theme-danger {
  --bs-text-opacity: 1;
  color: #ef0137 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.5rem !important;
  }
  .gap-sm-6 {
    gap: 2rem !important;
  }
  .gap-sm-7 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.5rem !important;
  }
  .m-sm-6 {
    margin: 2rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-6 {
    margin-top: 2rem !important;
  }
  .mt-sm-7 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1rem !important;
  }
  .me-sm-5 {
    margin-right: 1.5rem !important;
  }
  .me-sm-6 {
    margin-right: 2rem !important;
  }
  .me-sm-7 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-6 {
    margin-left: 2rem !important;
  }
  .ms-sm-7 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.5rem !important;
  }
  .p-sm-6 {
    padding: 2rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-6 {
    padding-top: 2rem !important;
  }
  .pt-sm-7 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-6 {
    padding-right: 2rem !important;
  }
  .pe-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-6 {
    padding-left: 2rem !important;
  }
  .ps-sm-7 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.5rem !important;
  }
  .gap-md-6 {
    gap: 2rem !important;
  }
  .gap-md-7 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.5rem !important;
  }
  .m-md-6 {
    margin: 2rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 1.5rem !important;
  }
  .mt-md-6 {
    margin-top: 2rem !important;
  }
  .mt-md-7 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1rem !important;
  }
  .me-md-5 {
    margin-right: 1.5rem !important;
  }
  .me-md-6 {
    margin-right: 2rem !important;
  }
  .me-md-7 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1rem !important;
  }
  .ms-md-5 {
    margin-left: 1.5rem !important;
  }
  .ms-md-6 {
    margin-left: 2rem !important;
  }
  .ms-md-7 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.5rem !important;
  }
  .p-md-6 {
    padding: 2rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1rem !important;
  }
  .pt-md-5 {
    padding-top: 1.5rem !important;
  }
  .pt-md-6 {
    padding-top: 2rem !important;
  }
  .pt-md-7 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1rem !important;
  }
  .pe-md-5 {
    padding-right: 1.5rem !important;
  }
  .pe-md-6 {
    padding-right: 2rem !important;
  }
  .pe-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1rem !important;
  }
  .ps-md-5 {
    padding-left: 1.5rem !important;
  }
  .ps-md-6 {
    padding-left: 2rem !important;
  }
  .ps-md-7 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.5rem !important;
  }
  .gap-lg-6 {
    gap: 2rem !important;
  }
  .gap-lg-7 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.5rem !important;
  }
  .m-lg-6 {
    margin: 2rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-6 {
    margin-top: 2rem !important;
  }
  .mt-lg-7 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1rem !important;
  }
  .me-lg-5 {
    margin-right: 1.5rem !important;
  }
  .me-lg-6 {
    margin-right: 2rem !important;
  }
  .me-lg-7 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-6 {
    margin-left: 2rem !important;
  }
  .ms-lg-7 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.5rem !important;
  }
  .p-lg-6 {
    padding: 2rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-6 {
    padding-top: 2rem !important;
  }
  .pt-lg-7 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-6 {
    padding-right: 2rem !important;
  }
  .pe-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-6 {
    padding-left: 2rem !important;
  }
  .ps-lg-7 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.5rem !important;
  }
  .gap-xl-6 {
    gap: 2rem !important;
  }
  .gap-xl-7 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.5rem !important;
  }
  .m-xl-6 {
    margin: 2rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-6 {
    margin-top: 2rem !important;
  }
  .mt-xl-7 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1rem !important;
  }
  .me-xl-5 {
    margin-right: 1.5rem !important;
  }
  .me-xl-6 {
    margin-right: 2rem !important;
  }
  .me-xl-7 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-6 {
    margin-left: 2rem !important;
  }
  .ms-xl-7 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.5rem !important;
  }
  .p-xl-6 {
    padding: 2rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-6 {
    padding-top: 2rem !important;
  }
  .pt-xl-7 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-6 {
    padding-right: 2rem !important;
  }
  .pe-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-6 {
    padding-left: 2rem !important;
  }
  .ps-xl-7 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.5rem !important;
  }
  .gap-xxl-6 {
    gap: 2rem !important;
  }
  .gap-xxl-7 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.5rem !important;
  }
  .m-xxl-6 {
    margin: 2rem !important;
  }
  .m-xxl-7 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-6 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-6 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 2rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-6 {
    margin-right: 2rem !important;
  }
  .me-xxl-7 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-6 {
    margin-left: 2rem !important;
  }
  .ms-xxl-7 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.5rem !important;
  }
  .p-xxl-6 {
    padding: 2rem !important;
  }
  .p-xxl-7 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-6 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-6 {
    padding-top: 2rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-6 {
    padding-right: 2rem !important;
  }
  .pe-xxl-7 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-6 {
    padding-left: 2rem !important;
  }
  .ps-xxl-7 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.icon-left {
  margin-right: 8px;
}

.icon-action:hover {
  opacity: 1;
}

.lead {
  font-size: 1.125rem;
  font-weight: 900;
}

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 3rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

h1,
.h1 {
  position: relative;
  padding-left: 30px;
  letter-spacing: -0.5px;
}
h1::before,
.h1::before {
  content: " ";
  position: absolute;
  top: 14px;
  bottom: 14px;
  left: 0;
  width: 6px;
  background: linear-gradient(to bottom, #00e6dc 0%, #00ffb9 100%);
}

h2,
.h2 {
  position: relative;
  padding-left: 24px;
  line-height: 52px;
  letter-spacing: -0.5px;
}
h2::before,
.h2::before {
  content: " ";
  position: absolute;
  top: 11px;
  bottom: 11px;
  left: 0;
  width: 4px;
  background: linear-gradient(to bottom, #00e6dc 0%, #00ffb9 100%);
}

h3,
.h3 {
  line-height: 46px;
  letter-spacing: -0.5px;
}

h5,
.h5 {
  line-height: 32px;
}

h6,
.h6 {
  line-height: 28px;
}

.blockquote {
  line-height: 58px;
  letter-spacing: -0.5px;
}
.blockquote::before {
  display: block;
  content: '"';
  font-style: italic;
  color: #00ffb9;
  font-size: 96px;
  margin-bottom: -25px;
  padding-top: 20px;
}

.intro {
  font-size: 22px;
  line-height: 32px;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 900;
}

small, .small {
  line-height: 20pt;
}

caption {
  font-size: 16px;
  line-height: 26pt;
}

.si-footer {
  width: 100%;
  padding-top: 55px;
  padding-bottom: 55px;
}
.si-footer > .container,
.si-footer > .container-fluid,
.si-footer > .container-sm,
.si-footer > .container-md,
.si-footer > .container-lg,
.si-footer > .container-xl {
  display: grid;
  align-items: center;
}
.si-footer .si-copyright-legal-links {
  display: flex;
  flex-flow: column;
}
.si-footer .si-locale-application-name {
  display: flex;
  flex-flow: column;
}
.si-footer .si-icons {
  font-size: 20px;
  display: flex;
  gap: 15px;
}
.si-footer .si-contact-us {
  justify-self: left;
}
.si-footer .nav-link:hover {
  text-decoration: underline;
}
.si-footer .nav-item:first-child .nav-link {
  padding-left: 0;
}
.si-footer .nav-item:last-child .nav-link {
  padding-right: 0;
}

@media (max-width: 991.98px) {
  .si-footer .nav-link,
  .si-footer .si-copyright,
  .si-footer .si-application-name {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 15px 0 !important;
  }
  .si-footer .si-contact-us {
    margin-top: 15px;
  }
}
@media (min-width: 992px) {
  .si-footer > .container,
  .si-footer > .container-fluid,
  .si-footer > .container-sm,
  .si-footer > .container-md,
  .si-footer > .container-lg,
  .si-footer > .container-xl {
    grid-gap: 1.875rem;
  }
  .si-footer.si-footer-legal-only > .container,
  .si-footer.si-footer-legal-only > .container-fluid,
  .si-footer.si-footer-legal-only > .container-sm,
  .si-footer.si-footer-legal-only > .container-md,
  .si-footer.si-footer-legal-only > .container-lg,
  .si-footer.si-footer-legal-only > .container-xl {
    grid-template-columns: 1fr auto;
    grid-template-areas: "application-name application-name" "copyright legal-links";
  }
  .si-footer.si-footer-intranet > .container,
  .si-footer.si-footer-intranet > .container-fluid,
  .si-footer.si-footer-intranet > .container-sm,
  .si-footer.si-footer-intranet > .container-md,
  .si-footer.si-footer-intranet > .container-lg,
  .si-footer.si-footer-intranet > .container-xl {
    grid-template-columns: 1fr auto;
    grid-template-areas: "copyright legal-links";
  }
  .si-footer.si-footer-extended > .container,
  .si-footer.si-footer-extended > .container-fluid,
  .si-footer.si-footer-extended > .container-sm,
  .si-footer.si-footer-extended > .container-md,
  .si-footer.si-footer-extended > .container-lg,
  .si-footer.si-footer-extended > .container-xl {
    grid-template-columns: 1fr auto;
    grid-template-areas: "locale-application-name icons" "copyright copyright" "legal-links contact-us";
  }
  .si-footer .si-application-name {
    grid-area: application-name;
  }
  .si-footer .si-copyright {
    grid-area: copyright;
  }
  .si-footer .si-legal-links {
    grid-area: legal-links;
  }
  .si-footer .si-locale {
    grid-area: locale;
  }
  .si-footer .si-icons {
    grid-area: icons;
  }
  .si-footer .si-contact-us {
    grid-area: contact-us;
    justify-self: right;
  }
  .si-footer .si-locale-application-name {
    grid-area: locale-application-name;
    flex-flow: row;
  }
}
@media (min-width: 1200px) {
  .si-footer.si-footer-legal-only > .container,
  .si-footer.si-footer-legal-only > .container-fluid,
  .si-footer.si-footer-legal-only > .container-sm,
  .si-footer.si-footer-legal-only > .container-md,
  .si-footer.si-footer-legal-only > .container-lg,
  .si-footer.si-footer-legal-only > .container-xl {
    grid-template-columns: 1fr auto auto;
    grid-template-areas: "application-name copyright legal-links";
  }
  .si-footer.si-footer-extended > .container,
  .si-footer.si-footer-extended > .container-fluid,
  .si-footer.si-footer-extended > .container-sm,
  .si-footer.si-footer-extended > .container-md,
  .si-footer.si-footer-extended > .container-lg,
  .si-footer.si-footer-extended > .container-xl {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "locale-application-name locale-application-name icons" "copyright legal-links contact-us";
  }
}
.si-header {
  --si-header-offset-top: 60px;
  --navbar-expand: 0;
}
.si-header > .container,
.si-header > .container-fluid,
.si-header > .container-sm,
.si-header > .container-md,
.si-header > .container-lg,
.si-header > .container-xl {
  display: grid;
  column-gap: 20px;
  padding: 15px 0.9375rem;
}

.si-header-internet > .container,
.si-header-internet > .container-fluid,
.si-header-internet > .container-sm,
.si-header-internet > .container-md,
.si-header-internet > .container-lg,
.si-header-internet > .container-xl {
  grid-template-columns: 1fr auto auto;
  grid-template-areas: "brand search toggler";
  grid-template-rows: 30px;
}

.si-header-extended {
  --si-header-offset-top: 110px;
}
.si-header-extended > .container,
.si-header-extended > .container-fluid,
.si-header-extended > .container-sm,
.si-header-extended > .container-md,
.si-header-extended > .container-lg,
.si-header-extended > .container-xl {
  grid-template-columns: auto auto 1fr;
  grid-template-areas: "brand brand nav-icons" ". . ." "toggler search search";
  grid-template-rows: 28px 12px 40px;
}

.si-header-intranet .si-header-nav-icons,
.si-header-intranet-application .si-header-nav-icons {
  display: none;
}
.si-header-intranet > .container,
.si-header-intranet > .container-fluid,
.si-header-intranet > .container-sm,
.si-header-intranet > .container-md,
.si-header-intranet > .container-lg,
.si-header-intranet > .container-xl,
.si-header-intranet-application > .container,
.si-header-intranet-application > .container-fluid,
.si-header-intranet-application > .container-sm,
.si-header-intranet-application > .container-md,
.si-header-intranet-application > .container-lg,
.si-header-intranet-application > .container-xl {
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: 30px;
  grid-template-areas: "toggler intra . brand";
}

.si-header-bordered, .si-header-intranet-application {
  border-bottom: 1px solid #b3b3be;
}

.si-header-nav, .si-header-nav-icons, .si-header-nav-additional, .si-header-nav-main {
  display: flex;
  position: relative;
  flex-flow: column;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.si-header-nav-icons {
  flex-flow: row;
  gap: 15px;
}

.si-header-brand {
  display: flex;
  grid-area: brand;
}

.si-header-collapse {
  display: none;
}
.si-header-collapse.show {
  display: block;
}

.si-header-nav, .si-header-nav-icons, .si-header-nav-additional, .si-header-nav-main,
.si-header-collapse {
  grid-area: nav;
}

.si-header-collapse, .si-header-dropdown-menu {
  position: fixed;
  top: var(--si-header-offset-top);
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  justify-content: stretch;
  z-index: 1031;
}
.si-header-collapse .si-header-link, .si-header-collapse .si-header-toggler, .si-header-dropdown-menu .si-header-link, .si-header-dropdown-menu .si-header-toggler {
  padding: 9px 10px;
}
.si-header-collapse .si-header-link:hover, .si-header-collapse .si-header-toggler:hover, .si-header-dropdown-menu .si-header-link:hover, .si-header-dropdown-menu .si-header-toggler:hover {
  background-color: #dfdfd9;
}
.si-header-collapse > .container, .si-header-dropdown-menu > .container,
.si-header-collapse > .si-header-collapse-wrapper > .container,
.si-header-dropdown-menu > .si-header-collapse-wrapper > .container,
.si-header-collapse > .si-header-dropdown-menu-wrapper > .container,
.si-header-dropdown-menu > .si-header-dropdown-menu-wrapper > .container,
.si-header-collapse > .si-header-collapse > .container-fluid,
.si-header-collapse > .si-header-collapse > .container-sm,
.si-header-collapse > .si-header-collapse > .container-md,
.si-header-collapse > .si-header-collapse > .container-lg,
.si-header-collapse > .si-header-collapse > .container-xl,
.si-header-collapse > .si-header-dropdown-menu-wrapper > .container-fluid,
.si-header-dropdown-menu > .si-header-dropdown-menu-wrapper > .container-fluid,
.si-header-collapse > .si-header-dropdown-menu-wrapper > .container-sm,
.si-header-dropdown-menu > .si-header-dropdown-menu-wrapper > .container-sm,
.si-header-collapse > .si-header-dropdown-menu-wrapper > .container-md,
.si-header-dropdown-menu > .si-header-dropdown-menu-wrapper > .container-md,
.si-header-collapse > .si-header-dropdown-menu-wrapper > .container-lg,
.si-header-dropdown-menu > .si-header-dropdown-menu-wrapper > .container-lg,
.si-header-collapse > .si-header-dropdown-menu-wrapper > .container-xl,
.si-header-dropdown-menu > .si-header-dropdown-menu-wrapper > .container-xl,
.si-header-collapse > .container-fluid,
.si-header-dropdown-menu > .container-fluid,
.si-header-collapse > .container-sm,
.si-header-dropdown-menu > .container-sm,
.si-header-collapse > .container-md,
.si-header-dropdown-menu > .container-md,
.si-header-collapse > .container-lg,
.si-header-dropdown-menu > .container-lg,
.si-header-collapse > .container-xl,
.si-header-dropdown-menu > .container-xl {
  display: flex;
  gap: 45px;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.si-header-dropdown-menu {
  overflow-y: auto;
}

.si-header-dropdown-nav {
  list-style: none;
  grid-area: dropdown-nav;
  padding-left: 0;
  width: 100%;
}

.si-header-dropdown-head {
  display: flex;
  grid-area: dropdown-head;
  flex-flow: column;
  width: 100%;
  position: sticky;
  top: 0;
}
.si-header-dropdown-head .si-header-link-close {
  position: relative;
}
.si-header-dropdown-head .si-header-link-close::before {
  content: "\ea0d";
  font-family: "simpl-siemens-brand-icons";
  font-size: 25px;
  position: absolute;
  left: -15px;
}
.si-header-dropdown-head .si-header-link-current {
  color: #008aa6;
  font-weight: 900;
}

.si-header-toggler {
  grid-area: toggler;
  width: fit-content;
  border: 0;
}

.si-header-search {
  grid-area: search;
  background: transparent;
  justify-self: end;
  border: 0;
  width: 100%;
}
.si-header-search .form-control {
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
}

.si-header-nav-main {
  width: 100%;
}

.si-header-nav-additional {
  width: 100%;
}

.si-header-nav-icons {
  grid-area: nav-icons;
  justify-self: right;
  align-self: center;
}

.si-header-platform {
  display: none;
  grid-area: platform;
  color: #000028;
}

.si-header-intranet-claim {
  display: flex;
  align-items: center;
  grid-area: intra;
}

.si-header-link, .si-header-toggler {
  color: #000028;
  display: block;
  white-space: nowrap;
  padding: 0;
  background: transparent;
  border: 0;
  width: 100%;
  text-align: left;
  text-decoration: none;
}
.si-header-link:hover, .si-header-toggler:hover {
  text-decoration: none;
}

.si-header-dropdown-nav .si-header-link:hover, .si-header-dropdown-nav .si-header-toggler:hover,
.si-header-nav-main .si-header-link:hover,
.si-header-nav-main .si-header-toggler:hover {
  color: #000028;
  background-color: #dfdfd9;
}

.si-header-nav-additional .si-header-link, .si-header-nav-additional .si-header-toggler {
  color: #0cc;
}
.si-header-nav-additional .si-header-link:hover, .si-header-nav-additional .si-header-toggler:hover {
  color: #0cc;
}

.si-header-nav .si-header-item, .si-header-nav-main .si-header-item, .si-header-nav-additional .si-header-item, .si-header-nav-icons .si-header-item {
  position: relative;
}
.si-header-nav .si-header-item:last-child .si-header-link, .si-header-nav-main .si-header-item:last-child .si-header-link, .si-header-nav-additional .si-header-item:last-child .si-header-link, .si-header-nav-icons .si-header-item:last-child .si-header-link, .si-header-nav .si-header-item:last-child .si-header-toggler, .si-header-nav-main .si-header-item:last-child .si-header-toggler, .si-header-nav-additional .si-header-item:last-child .si-header-toggler, .si-header-nav-icons .si-header-item:last-child .si-header-toggler {
  margin-right: 0;
}

.si-header-dropdown-toggle {
  display: flex;
  justify-content: space-between;
}
.si-header-dropdown-toggle::after {
  content: "\ea0e";
  font-family: "simpl-siemens-brand-icons";
  font-size: 25px;
  margin: -6px 0;
}

.si-header-dropdown-close {
  display: none;
  grid-area: dropdown-close;
  position: sticky;
  top: 0;
}

.si-header-underline {
  display: none;
  border-top: 2px solid #008aa6;
  position: absolute;
  bottom: var(--si-header-underline-bottom, -15px);
  opacity: 1;
  transition: left 0.5s, width 0.5s, opacity 0.2s cubic-bezier(0.12, 0, 0.5, 1) 0.5s;
}

.si-header-collapse .si-header-link, .si-header-collapse .si-header-toggler,
.si-header-nav-icons .si-header-link,
.si-header-nav-icons .si-header-toggler {
  padding: 0;
  display: inline;
}
.si-header-collapse .si-header-link:hover, .si-header-collapse .si-header-toggler:hover,
.si-header-nav-icons .si-header-link:hover,
.si-header-nav-icons .si-header-toggler:hover {
  background: transparent;
}
.si-header-collapse .si-header-link::after, .si-header-collapse .si-header-toggler::after,
.si-header-nav-icons .si-header-link::after,
.si-header-nav-icons .si-header-toggler::after {
  display: none;
}

@media (min-width: 768px) {
  .si-header-internet {
    --si-header-offset-top: 120px;
    --navbar-expand: 1;
  }
  .si-header-internet .si-header-toggler {
    display: none;
  }
  .si-header-internet .si-header-collapse {
    position: static;
    background: transparent;
    display: block;
    align-self: end;
  }
  .si-header-internet .si-header-collapse .container,
  .si-header-internet .si-header-collapse .container-fluid,
  .si-header-internet .si-header-collapse .container-sm,
  .si-header-internet .si-header-collapse .container-md,
  .si-header-internet .si-header-collapse .container-lg,
  .si-header-internet .si-header-collapse .container-xl {
    display: flex;
    flex-flow: row;
    padding-right: 0;
    padding-left: 0;
  }
  .si-header-internet .si-header-collapse .si-header-nav, .si-header-internet .si-header-collapse .si-header-nav-main, .si-header-internet .si-header-collapse .si-header-nav-additional, .si-header-internet .si-header-collapse .si-header-nav-icons {
    flex-flow: row;
    gap: 15px;
  }
  .si-header-internet .si-header-collapse .si-header-nav-additional {
    margin-left: auto;
    width: auto;
  }
  .si-header-internet .si-header-collapse .si-header-nav-additional .si-header-link, .si-header-internet .si-header-collapse .si-header-nav-additional .si-header-toggler {
    color: #000028;
  }
  .si-header-internet .si-header-collapse .si-header-nav-additional .si-header-link:hover, .si-header-internet .si-header-collapse .si-header-nav-additional .si-header-toggler:hover {
    color: #005159;
  }
  .si-header-internet .si-header-collapse .si-header-nav-main .si-header-link, .si-header-internet .si-header-collapse .si-header-nav-main .si-header-toggler {
    font-weight: 900;
  }
  .si-header-internet .si-header-collapse .si-header-nav-main .si-header-link.si-header-server-underline, .si-header-internet .si-header-collapse .si-header-nav-main .si-header-server-underline.si-header-toggler {
    margin-bottom: -15px;
    padding-bottom: 13px;
    border-bottom: 2px solid #0cc;
  }
  .si-header-internet .si-header-dropdown-menu {
    background-color: #f3f3f0;
  }
  .si-header-internet .si-header-dropdown-menu .container,
  .si-header-internet .si-header-dropdown-menu .container-fluid,
  .si-header-internet .si-header-dropdown-menu .container-sm,
  .si-header-internet .si-header-dropdown-menu .container-md,
  .si-header-internet .si-header-dropdown-menu .container-lg,
  .si-header-internet .si-header-dropdown-menu .container-xl {
    display: grid;
    grid-template-columns: 3fr 1fr 4fr auto;
    grid-template-rows: 46px auto;
    grid-template-areas: "dropdown-close dropdown-close dropdown-close dropdown-close" "dropdown-head . dropdown-nav .";
    justify-content: stretch;
  }
  .si-header-internet .si-header-dropdown-close {
    display: block;
    padding: 0;
    justify-self: end;
    grid-area: dropdown-close;
  }
  .si-header-internet .si-header-dropdown-close:hover {
    background: transparent;
  }
  .si-header-internet .si-header-underline {
    display: block;
  }
  .si-header-internet > .container,
  .si-header-internet > .container-fluid,
  .si-header-internet > .container-sm,
  .si-header-internet > .container-md,
  .si-header-internet > .container-lg,
  .si-header-internet > .container-xl {
    row-gap: 10px;
    grid-template-rows: 40px 40px;
    grid-template-columns: 1fr auto;
    grid-template-areas: "brand search" "nav nav";
  }
  .si-header-extended {
    --si-header-offset-top: 120px;
  }
  .si-header-extended > .container,
  .si-header-extended > .container-fluid,
  .si-header-extended > .container-sm,
  .si-header-extended > .container-md,
  .si-header-extended > .container-lg,
  .si-header-extended > .container-xl {
    grid-template-rows: 28px 40px;
    row-gap: 22px;
    grid-template-columns: auto auto 1fr;
    grid-template-areas: "brand platform nav-icons" "toggler . search";
  }
  .si-header-extended > .container .si-header-search,
  .si-header-extended > .container-fluid .si-header-search,
  .si-header-extended > .container-sm .si-header-search,
  .si-header-extended > .container-md .si-header-search,
  .si-header-extended > .container-lg .si-header-search,
  .si-header-extended > .container-xl .si-header-search {
    width: 360px;
  }
  .si-header-intranet {
    --si-header-offset-top: 100px;
  }
  .si-header-intranet > .container,
  .si-header-intranet > .container-fluid,
  .si-header-intranet > .container-sm,
  .si-header-intranet > .container-md,
  .si-header-intranet > .container-lg,
  .si-header-intranet > .container-xl {
    grid-template-rows: 70px;
  }
  .si-header-intranet > .container,
  .si-header-intranet > .container-fluid,
  .si-header-intranet > .container-sm,
  .si-header-intranet > .container-md,
  .si-header-intranet > .container-lg,
  .si-header-intranet > .container-xl,
  .si-header-intranet-application > .container,
  .si-header-intranet-application > .container-fluid,
  .si-header-intranet-application > .container-sm,
  .si-header-intranet-application > .container-md,
  .si-header-intranet-application > .container-lg,
  .si-header-intranet-application > .container-xl {
    grid-template-columns: auto auto auto 1fr auto auto;
    grid-template-areas: "toggler intra platform . nav-icons brand";
    align-items: center;
  }
  .si-header-intranet .si-header-nav-icons,
  .si-header-intranet-application .si-header-nav-icons {
    display: flex;
  }
  .si-header-platform {
    display: inline;
  }
}
@media (min-width: 992px) {
  .si-header-internet {
    --si-header-offset-top: 100px;
    --si-header-underline-bottom: -36px;
  }
  .si-header-internet > .container,
  .si-header-internet > .container-fluid,
  .si-header-internet > .container-sm,
  .si-header-internet > .container-md,
  .si-header-internet > .container-lg,
  .si-header-internet > .container-xl {
    grid-template-columns: auto 1fr min-content;
    grid-template-rows: 70px;
    grid-template-areas: "brand nav search";
  }
  .si-header-internet .si-header-collapse {
    align-self: center;
  }
  .si-header-extended {
    --navbar-expand: 1;
  }
  .si-header-extended .si-header-toggler {
    display: none;
  }
  .si-header-extended .si-header-collapse {
    position: static;
    background: transparent;
    display: block;
    align-self: end;
  }
  .si-header-extended .si-header-collapse .container,
  .si-header-extended .si-header-collapse .container-fluid,
  .si-header-extended .si-header-collapse .container-sm,
  .si-header-extended .si-header-collapse .container-md,
  .si-header-extended .si-header-collapse .container-lg,
  .si-header-extended .si-header-collapse .container-xl {
    display: flex;
    flex-flow: row;
    padding-right: 0;
    padding-left: 0;
  }
  .si-header-extended .si-header-collapse .si-header-nav, .si-header-extended .si-header-collapse .si-header-nav-main, .si-header-extended .si-header-collapse .si-header-nav-additional, .si-header-extended .si-header-collapse .si-header-nav-icons {
    flex-flow: row;
    gap: 15px;
  }
  .si-header-extended .si-header-collapse .si-header-nav-additional {
    margin-left: auto;
    width: auto;
  }
  .si-header-extended .si-header-collapse .si-header-nav-additional .si-header-link, .si-header-extended .si-header-collapse .si-header-nav-additional .si-header-toggler {
    color: #000028;
  }
  .si-header-extended .si-header-collapse .si-header-nav-additional .si-header-link:hover, .si-header-extended .si-header-collapse .si-header-nav-additional .si-header-toggler:hover {
    color: #005159;
  }
  .si-header-extended .si-header-collapse .si-header-nav-main .si-header-link, .si-header-extended .si-header-collapse .si-header-nav-main .si-header-toggler {
    font-weight: 900;
  }
  .si-header-extended .si-header-collapse .si-header-nav-main .si-header-link.si-header-server-underline, .si-header-extended .si-header-collapse .si-header-nav-main .si-header-server-underline.si-header-toggler {
    margin-bottom: -15px;
    padding-bottom: 13px;
    border-bottom: 2px solid #0cc;
  }
  .si-header-extended .si-header-dropdown-menu {
    background-color: #f3f3f0;
  }
  .si-header-extended .si-header-dropdown-menu .container,
  .si-header-extended .si-header-dropdown-menu .container-fluid,
  .si-header-extended .si-header-dropdown-menu .container-sm,
  .si-header-extended .si-header-dropdown-menu .container-md,
  .si-header-extended .si-header-dropdown-menu .container-lg,
  .si-header-extended .si-header-dropdown-menu .container-xl {
    display: grid;
    grid-template-columns: 3fr 1fr 4fr auto;
    grid-template-rows: 46px auto;
    grid-template-areas: "dropdown-close dropdown-close dropdown-close dropdown-close" "dropdown-head . dropdown-nav .";
    justify-content: stretch;
  }
  .si-header-extended .si-header-dropdown-close {
    display: block;
    padding: 0;
    justify-self: end;
    grid-area: dropdown-close;
  }
  .si-header-extended .si-header-dropdown-close:hover {
    background: transparent;
  }
  .si-header-extended .si-header-underline {
    display: block;
  }
  .si-header-extended > .container,
  .si-header-extended > .container-fluid,
  .si-header-extended > .container-sm,
  .si-header-extended > .container-md,
  .si-header-extended > .container-lg,
  .si-header-extended > .container-xl {
    grid-template-rows: 40px 40px;
    row-gap: 10px;
    grid-template-columns: auto 1fr auto 40px auto;
    grid-template-areas: "brand platform search . nav-icons" "nav nav nav nav nav";
  }
  .si-header-extended > .container .si-header-search,
  .si-header-extended > .container-fluid .si-header-search,
  .si-header-extended > .container-sm .si-header-search,
  .si-header-extended > .container-md .si-header-search,
  .si-header-extended > .container-lg .si-header-search,
  .si-header-extended > .container-xl .si-header-search {
    width: 480px;
  }
  .si-header-intranet-application {
    --si-header-offset-top: 56px;
    --si-header-underline-bottom: -13px;
    --navbar-expand: 1;
  }
  .si-header-intranet-application .si-header-toggler {
    display: none;
  }
  .si-header-intranet-application .si-header-collapse {
    position: static;
    background: transparent;
    display: block;
    align-self: end;
  }
  .si-header-intranet-application .si-header-collapse .container,
  .si-header-intranet-application .si-header-collapse .container-fluid,
  .si-header-intranet-application .si-header-collapse .container-sm,
  .si-header-intranet-application .si-header-collapse .container-md,
  .si-header-intranet-application .si-header-collapse .container-lg,
  .si-header-intranet-application .si-header-collapse .container-xl {
    display: flex;
    flex-flow: row;
    padding-right: 0;
    padding-left: 0;
  }
  .si-header-intranet-application .si-header-collapse .si-header-nav, .si-header-intranet-application .si-header-collapse .si-header-nav-main, .si-header-intranet-application .si-header-collapse .si-header-nav-additional, .si-header-intranet-application .si-header-collapse .si-header-nav-icons {
    flex-flow: row;
    gap: 15px;
  }
  .si-header-intranet-application .si-header-collapse .si-header-nav-additional {
    margin-left: auto;
    width: auto;
  }
  .si-header-intranet-application .si-header-collapse .si-header-nav-additional .si-header-link, .si-header-intranet-application .si-header-collapse .si-header-nav-additional .si-header-toggler {
    color: #000028;
  }
  .si-header-intranet-application .si-header-collapse .si-header-nav-additional .si-header-link:hover, .si-header-intranet-application .si-header-collapse .si-header-nav-additional .si-header-toggler:hover {
    color: #005159;
  }
  .si-header-intranet-application .si-header-collapse .si-header-nav-main .si-header-link, .si-header-intranet-application .si-header-collapse .si-header-nav-main .si-header-toggler {
    font-weight: 900;
  }
  .si-header-intranet-application .si-header-collapse .si-header-nav-main .si-header-link.si-header-server-underline, .si-header-intranet-application .si-header-collapse .si-header-nav-main .si-header-server-underline.si-header-toggler {
    margin-bottom: -15px;
    padding-bottom: 13px;
    border-bottom: 2px solid #0cc;
  }
  .si-header-intranet-application .si-header-dropdown-menu {
    background-color: #f3f3f0;
  }
  .si-header-intranet-application .si-header-dropdown-menu .container,
  .si-header-intranet-application .si-header-dropdown-menu .container-fluid,
  .si-header-intranet-application .si-header-dropdown-menu .container-sm,
  .si-header-intranet-application .si-header-dropdown-menu .container-md,
  .si-header-intranet-application .si-header-dropdown-menu .container-lg,
  .si-header-intranet-application .si-header-dropdown-menu .container-xl {
    display: grid;
    grid-template-columns: 3fr 1fr 4fr auto;
    grid-template-rows: 46px auto;
    grid-template-areas: "dropdown-close dropdown-close dropdown-close dropdown-close" "dropdown-head . dropdown-nav .";
    justify-content: stretch;
  }
  .si-header-intranet-application .si-header-dropdown-close {
    display: block;
    padding: 0;
    justify-self: end;
    grid-area: dropdown-close;
  }
  .si-header-intranet-application .si-header-dropdown-close:hover {
    background: transparent;
  }
  .si-header-intranet-application .si-header-underline {
    display: block;
  }
  .si-header-intranet-application > .container,
  .si-header-intranet-application > .container-fluid,
  .si-header-intranet-application > .container-sm,
  .si-header-intranet-application > .container-md,
  .si-header-intranet-application > .container-lg,
  .si-header-intranet-application > .container-xl {
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 25px;
    grid-template-areas: "intra platform nav brand";
    align-items: center;
  }
  .si-header-intranet-application .si-header-collapse {
    align-self: center;
  }
}
.loading-spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 4;
}
.loading-spinner.global-spinner {
  position: fixed;
  z-index: 4000;
}

.spinner-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skeleton-text {
  position: relative;
  display: block;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  height: calc(var(--skeleton-height, 1.5em));
  width: 100%;
}
.skeleton-text::before {
  content: "";
  background: linear-gradient(90deg, #ebebee, #fff);
  position: absolute;
  width: 100%;
  left: 0;
  top: calc((var(--skeleton-height, 1.5em) - 0.75em) * 0.5);
  right: 0;
  bottom: calc((var(--skeleton-height, 1.5em) - 0.75em) * 0.5);
}
.skeleton-text::after {
  content: "";
  position: absolute;
  top: calc((var(--skeleton-height, 1.5em) - 0.75em) * 0.5);
  right: 0;
  bottom: calc((var(--skeleton-height, 1.5em) - 0.75em) * 0.5);
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(0, 0, 40, 0) 0, rgba(0, 0, 40, 0.2) 20%, rgba(0, 0, 40, 0.5) 60%, rgba(0, 0, 40, 0));
  animation: shimmer cubic-bezier(0.12, 0, 0.5, 1) 1s infinite;
}

@keyframes shimmer {
  to {
    transform: translateX(100%);
  }
}
h1,
.h1,
h4,
.h4 {
  --skeleton-height: 1.2em;
}

h2,
.h2 {
  --skeleton-height: $h2-line-height;
}

h3,
.h3 {
  --skeleton-height: $h3-line-height;
}

h5,
.h5 {
  --skeleton-height: $h5-line-height;
}

h6,
.h6 {
  --skeleton-height: $h6-line-height;
}

.si-chip-input {
  width: 100%;
  cursor: text;
}
.si-chip-input .form-control {
  display: flex;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  height: auto;
  min-height: 3.5rem;
}
.si-chip-input .form-control input {
  padding: 0;
  height: calc(3.5rem - 30px);
  border: 0;
  min-width: 100px;
  flex: 1;
  background: transparent;
  color: inherit;
}
.si-chip-input.ng-invalid.ng-touched .form-control, .si-chip-input.si-invalid .form-control {
  border-color: #ef0137;
  color: #ef0137;
  background: #fcccd7;
}

.si-chip-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 9px 5px;
  margin-right: 5px;
  z-index: 1;
}

.si-chip {
  display: flex;
  align-items: center;
  background-color: #00d7a0;
  padding: 0 5px;
  white-space: nowrap;
  color: #000028;
  height: calc(3.5rem - 30px);
  line-height: 1;
  overflow: hidden;
}

.si-chip-remove {
  color: inherit;
  border: 0;
  background: transparent;
  padding: 0 3px 0 5px;
  margin: 2px 0 2px 5px;
  border-left: 1px #000028 solid;
}

typeahead-container {
  right: 0;
}
typeahead-container .dropdown-menu > li.active > a {
  background-color: #dbe8f0;
  color: #000;
}

/**
* @license
* Copyright Siemens AG, 2017
* Licensed under the Siemens Inner Source License 1.1
*/
.ngx-datatable.newton {
  background: transparent;
}
.ngx-datatable.newton.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.newton.multi-click-selection .datatable-body-row.active, .ngx-datatable.newton.multi-click-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.newton.multi-selection .datatable-body-row.active, .ngx-datatable.newton.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.newton.single-selection .datatable-body-row.active, .ngx-datatable.newton.single-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.newton.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.newton.multi-click-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.newton.multi-selection .datatable-body-row.active:hover, .ngx-datatable.newton.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.newton.single-selection .datatable-body-row.active:hover, .ngx-datatable.newton.single-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.newton.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.newton.multi-click-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.newton.multi-selection .datatable-body-row.active:focus, .ngx-datatable.newton.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.newton.single-selection .datatable-body-row.active:focus, .ngx-datatable.newton.single-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.newton:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.newton:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.newton:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.newton:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell:hover, .ngx-datatable.newton.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell:focus, .ngx-datatable.newton.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell.active, .ngx-datatable.newton.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.newton.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.newton.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.newton.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.newton .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.newton .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.newton .datatable-body .datatable-row-left,
.ngx-datatable.newton .datatable-header .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==");
}
.ngx-datatable.newton .datatable-body .datatable-row-right,
.ngx-datatable.newton .datatable-header .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==");
}
.ngx-datatable.newton .datatable-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.newton .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  font-weight: 500;
  vertical-align: bottom;
}
.ngx-datatable.newton .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.newton .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.newton .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.newton .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: 0;
}
.ngx-datatable.newton .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.newton .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.newton .datatable-body .datatable-body-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.newton .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  transition: width 0.3s ease;
}
.ngx-datatable.newton .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.ngx-datatable.newton .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
  background-color: rgb(170, 209, 249);
}
.ngx-datatable.newton .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: rgb(16, 108, 200);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.newton .datatable-footer {
  font-size: 14px;
}
.ngx-datatable.newton .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.newton .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.newton .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.newton .datatable-footer .datatable-pager li.disabled a {
  color: #9bafbe !important;
  background-color: transparent !important;
}
.ngx-datatable.newton .datatable-footer .datatable-pager li.active a {
  background-color: #005578;
  color: #fff;
  font-weight: normal;
}
.ngx-datatable.newton .datatable-footer .datatable-pager a {
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  padding: 0;
  margin: 0 2px;
  border-radius: 0;
  border-style: none;
  text-align: center;
  vertical-align: top;
  vertical-align: bottom;
  color: #005578;
  text-decoration: none;
}
.ngx-datatable.newton .datatable-footer .datatable-pager a:hover {
  color: #fff;
  background-color: #005578;
}
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-left,
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-prev,
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-right,
.ngx-datatable.newton .datatable-footer .datatable-pager .icon-skip {
  font-size: 20px;
  line-height: 20px;
  padding: 0;
}

.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]::before {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked::before {
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
.si-invalid .invalid-feedback {
  display: block;
}
.si-invalid .invalid-feedback:empty {
  display: none;
}

.platform-server ul.navbar-primary > li.server-navbar-underline > a::after,
.platform-server ul.navbar-primary > li:hover > a::after {
  content: " ";
  border-bottom: 4px solid #099;
  position: absolute;
  width: calc(100% - 20px);
  left: 0;
  bottom: -3px;
}

/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #d1fff2;
  position: relative;
  z-index: 1;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .bs-datepicker-buttons */
}
.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}
.bs-datepicker bs-day-picker {
  float: left;
}
.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active, .bs-datepicker-btns button:hover, .bs-datepicker-btns button:focus, .bs-datepicker-btns button:active, .bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:focus {
  outline: none;
}
.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}
.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #000028;
  transition: 0.3s;
}
.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}
.bs-datepicker-head button.previous span {
  transform: translate(-1px, -1px);
}
.bs-datepicker-head button.next span {
  transform: translate(1px, -1px);
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}
.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #d1fff2;
  /* .bs-datepicker-body table */
}
.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}
.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}
.bs-datepicker-body table th {
  font-size: 13px;
  color: #262648;
  font-weight: 400;
  text-align: center;
}
.bs-datepicker-body table td {
  color: #000028;
  text-align: center;
  position: relative;
  padding: 0;
  /* todo: check first :not(.disabled) usage */
}
.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  /*z-index: 1;*/
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #c5ffef;
  transition: 0s;
}
.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #c5ffef;
  transition: 0s;
  cursor: pointer;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
  color: #262648;
}
.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span {
  color: #000028;
}
.bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}
.bs-datepicker-body table td.active {
  position: relative;
}
.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}
.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}
.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after, .bs-datepicker-body table td.active.select-start span:after, .bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -3px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}
.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}
.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}
.bs-datepicker-body table td span[class*=select-], .bs-datepicker-body table td[class*=select-] span {
  border-radius: 50%;
  color: #000028;
}
.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #c5ffef;
}
.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}
.bs-datepicker-body table.days span.is-highlighted.in-range:before {
  right: 3px;
  left: 0;
}
.bs-datepicker-body table.days span.in-range.select-end:before {
  right: 4px;
  left: 0;
}
.bs-datepicker-body table.days td.select-start + td.select-end:before, .bs-datepicker-body table.days td.select-start + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.select-end:before, .bs-datepicker-body table.days td.in-range + td.is-highlighted:before, .bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #c5ffef;
  width: 100%;
}
.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}
.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}
.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}
.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}
.bs-datepicker-body table.months td {
  height: 52px;
}
.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}
.bs-datepicker .current-timedate {
  color: #000028;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #d1fff2;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}
.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}
.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}
.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}
.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}
.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}
.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}
.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}
.bs-datepicker-container {
  padding: 15px;
}
.bs-datepicker .bs-media-container {
  display: flex;
}
@media (max-width: 768px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
}
.bs-datepicker .bs-timepicker-in-datepicker-container {
  display: flex;
  justify-content: space-around;
}
.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}
.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #000028;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}
.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}
.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #d1fff2;
}
.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}
.bs-datepicker-buttons .btn-today-wrapper {
  display: flex;
  flex-flow: row wrap;
}
.bs-datepicker-buttons .clear-right,
.bs-datepicker-buttons .today-right {
  flex-grow: 0;
}
.bs-datepicker-buttons .clear-left,
.bs-datepicker-buttons .today-left {
  flex-grow: 1;
}
.bs-datepicker-buttons .clear-center,
.bs-datepicker-buttons .today-center {
  flex-grow: 0.5;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}
.bs-timepicker-label {
  color: #000028;
  margin-bottom: 10px;
}
.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #000028;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}
.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}
.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #d1fff2;
}
.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #d1fff2;
  background: #fff;
  color: #000028;
  font-size: 13px;
}
.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker {
    width: min-content;
    justify-content: center;
  }
  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* theming */
.bs-datepicker-container {
  padding: 0;
}

.bs-datepicker-head {
  border-radius: 0;
}
.bs-datepicker-head button {
  font-weight: 900;
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  font-weight: initial;
}
.bs-datepicker-head button:hover {
  background-color: transparent;
}

.bs-datepicker-body table th {
  height: 23px;
  min-width: 34px;
}
.bs-datepicker-body table td span {
  border-radius: 0;
}
.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*=select-], .bs-datepicker-body table td[class*=select-] span {
  border-radius: 0;
  font-weight: 900;
}
.bs-datepicker-body table td span.in-range {
  font-weight: 900;
}
.bs-datepicker-body table td:hover {
  font-weight: 900;
}

bs-datepicker-navigation-view {
  white-space: nowrap;
}

.theme-green .bs-datepicker-head {
  background-color: #fff;
}
.theme-green .btn-today-wrapper .btn-success, .theme-green .btn-clear-wrapper .btn-success {
  background-color: #fff;
  border-color: #fff;
}
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-green .btn-today-wrapper .btn-success:focus, .theme-green .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.theme-green .btn-today-wrapper .btn-success:hover, .theme-green .btn-clear-wrapper .btn-success:hover {
  background-color: #218838;
  border-color: #218838;
}
.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #fff;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #fff;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #fff;
}
.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #fff;
  color: #000028;
  opacity: 0.5;
  transition: 0s;
}

.theme-green .bs-datepicker-head button.previous,
.theme-green .bs-datepicker-head button.next {
  color: #008aa6;
}
.theme-green .bs-datepicker-body table td {
  color: #007082;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]::after, .theme-green .bs-datepicker-body table td[class*=select-] span::after {
  background-color: #62eec7;
  color: #000028;
}
.theme-green .bs-datepicker-body table td span.is-other-month, .theme-green .bs-datepicker-body table td.is-other-month span {
  color: #737389;
}
.theme-green .bs-datepicker-body table td span.in-range {
  color: #000028;
}
.theme-green .bs-datepicker-body table td:hover {
  color: #000028;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #4c4c68;
}
.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
}

@font-face {
  font-family: "simpl-siemens-brand-icons";
  src: url("../fonts/simpl-siemens-brand-icons.woff2") format("woff2"), url("../fonts/simpl-siemens-brand-icons.woff") format("woff"), url("../fonts/simpl-siemens-brand-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
[class^=siemens-],
[class*=" siemens-"] {
  display: inline-block;
  font-family: "simpl-siemens-brand-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.siemens-3d::before {
  content: "\ea01";
}

.siemens-acknowledge-events::before {
  content: "\ea02";
}

.siemens-add-workorder::before {
  content: "\ea03";
}

.siemens-aerospace::before {
  content: "\ea04";
}

.siemens-apps::before {
  content: "\ea05";
}

.siemens-arrow-double-left::before {
  content: "\ea06";
}

.siemens-arrow-double-right::before {
  content: "\ea07";
}

.siemens-arrow-down::before {
  content: "\ea08";
}

.siemens-arrow-left::before {
  content: "\ea09";
}

.siemens-arrow-right::before {
  content: "\ea0a";
}

.siemens-arrow-up::before {
  content: "\ea0b";
}

.siemens-arrowhead-down::before {
  content: "\ea0c";
}

.siemens-arrowhead-left::before {
  content: "\ea0d";
}

.siemens-arrowhead-right::before {
  content: "\ea0e";
}

.siemens-arrowhead-up::before {
  content: "\ea0f";
}

.siemens-attach::before {
  content: "\ea10";
}

.siemens-audiodescription-1::before {
  content: "\ea11";
}

.siemens-audiodescription-2::before {
  content: "\ea12";
}

.siemens-automotive::before {
  content: "\ea13";
}

.siemens-availability::before {
  content: "\ea14";
}

.siemens-back::before {
  content: "\ea15";
}

.siemens-backlog::before {
  content: "\ea16";
}

.siemens-bank::before {
  content: "\ea17";
}

.siemens-bar-chart::before {
  content: "\ea18";
}

.siemens-bell-pos-rbg::before {
  content: "\ea19";
}

.siemens-beverage-vending-machine::before {
  content: "\ea1a";
}

.siemens-beverages::before {
  content: "\ea1b";
}

.siemens-bicycle-parking::before {
  content: "\ea1c";
}

.siemens-bicycle::before {
  content: "\ea1d";
}

.siemens-bilibili::before {
  content: "\ea1e";
}

.siemens-biomass::before {
  content: "\ea1f";
}

.siemens-bookmark::before {
  content: "\ea20";
}

.siemens-box-check::before {
  content: "\ea21";
}

.siemens-box-cross::before {
  content: "\ea22";
}

.siemens-browse-all::before {
  content: "\ea23";
}

.siemens-buildings::before {
  content: "\ea24";
}

.siemens-bulk-material-handling::before {
  content: "\ea25";
}

.siemens-cafeteria::before {
  content: "\ea26";
}

.siemens-calendar::before {
  content: "\ea27";
}

.siemens-capacity::before {
  content: "\ea28";
}

.siemens-car-parking::before {
  content: "\ea29";
}

.siemens-car::before {
  content: "\ea2a";
}

.siemens-cement::before {
  content: "\ea2b";
}

.siemens-central-generation::before {
  content: "\ea2c";
}

.siemens-chat-1::before {
  content: "\ea2d";
}

.siemens-chat-2::before {
  content: "\ea2e";
}

.siemens-check-small::before {
  content: "\ea2f";
}

.siemens-check::before {
  content: "\ea30";
}

.siemens-chemicals::before {
  content: "\ea31";
}

.siemens-cities::before {
  content: "\ea32";
}

.siemens-clean-coding::before {
  content: "\ea33";
}

.siemens-cleaning-materials-storeroom::before {
  content: "\ea34";
}

.siemens-client::before {
  content: "\ea35";
}

.siemens-cloakroom::before {
  content: "\ea36";
}

.siemens-close-big::before {
  content: "\ea37";
}

.siemens-close-small::before {
  content: "\ea38";
}

.siemens-close::before {
  content: "\ea39";
}

.siemens-combustor::before {
  content: "\ea3a";
}

.siemens-communication::before {
  content: "\ea3b";
}

.siemens-community::before {
  content: "\ea3c";
}

.siemens-competitors::before {
  content: "\ea3d";
}

.siemens-compressor-turbine::before {
  content: "\ea3e";
}

.siemens-compressor::before {
  content: "\ea3f";
}

.siemens-computer::before {
  content: "\ea40";
}

.siemens-conference-room::before {
  content: "\ea41";
}

.siemens-configuration::before {
  content: "\ea42";
}

.siemens-configure::before {
  content: "\ea43";
}

.siemens-connected-radio-waves::before {
  content: "\ea44";
}

.siemens-connected-wlan-high::before {
  content: "\ea45";
}

.siemens-connected-wlan-low::before {
  content: "\ea46";
}

.siemens-connected-wlan-medium::before {
  content: "\ea47";
}

.siemens-construction::before {
  content: "\ea48";
}

.siemens-contact::before {
  content: "\ea49";
}

.siemens-copy-room::before {
  content: "\ea4a";
}

.siemens-copy-shop::before {
  content: "\ea4b";
}

.siemens-copy::before {
  content: "\ea4c";
}

.siemens-core-engine::before {
  content: "\ea4d";
}

.siemens-couplings::before {
  content: "\ea4e";
}

.siemens-crane::before {
  content: "\ea4f";
}

.siemens-cross-section::before {
  content: "\ea50";
}

.siemens-cross-selling::before {
  content: "\ea51";
}

.siemens-customer::before {
  content: "\ea52";
}

.siemens-cut::before {
  content: "\ea53";
}

.siemens-danger-of-scalding::before {
  content: "\ea54";
}

.siemens-data-centers::before {
  content: "\ea55";
}

.siemens-data-management::before {
  content: "\ea56";
}

.siemens-day-care-center::before {
  content: "\ea57";
}

.siemens-definition-of-done::before {
  content: "\ea58";
}

.siemens-delivery-trucks::before {
  content: "\ea59";
}

.siemens-delivery::before {
  content: "\ea5a";
}

.siemens-demo-material::before {
  content: "\ea5b";
}

.siemens-desinfection::before {
  content: "\ea5c";
}

.siemens-development-implementation::before {
  content: "\ea5d";
}

.siemens-devops::before {
  content: "\ea5e";
}

.siemens-digital-factory::before {
  content: "\ea5f";
}

.siemens-directional-arrow::before {
  content: "\ea60";
}

.siemens-disabled-access::before {
  content: "\ea61";
}

.siemens-disabled-parking::before {
  content: "\ea62";
}

.siemens-disabled-restroom::before {
  content: "\ea63";
}

.siemens-disconnected-radio-waves::before {
  content: "\ea64";
}

.siemens-disconnected-wlan::before {
  content: "\ea65";
}

.siemens-discrete-material-handling::before {
  content: "\ea66";
}

.siemens-dishwasher::before {
  content: "\ea67";
}

.siemens-distributed-generation::before {
  content: "\ea68";
}

.siemens-distributors::before {
  content: "\ea69";
}

.siemens-document-doc::before {
  content: "\ea6a";
}

.siemens-document-mp::before {
  content: "\ea6b";
}

.siemens-document-pdf::before {
  content: "\ea6c";
}

.siemens-document-ppt::before {
  content: "\ea6d";
}

.siemens-document-success::before {
  content: "\ea6e";
}

.siemens-document-txt::before {
  content: "\ea6f";
}

.siemens-document-xls::before {
  content: "\ea70";
}

.siemens-document-zip::before {
  content: "\ea71";
}

.siemens-document::before {
  content: "\ea72";
}

.siemens-documentation::before {
  content: "\ea73";
}

.siemens-dollar::before {
  content: "\ea74";
}

.siemens-download::before {
  content: "\ea75";
}

.siemens-drag-drop::before {
  content: "\ea76";
}

.siemens-drill-rig::before {
  content: "\ea77";
}

.siemens-drinking-water::before {
  content: "\ea78";
}

.siemens-drop-down::before {
  content: "\ea79";
}

.siemens-e-bike::before {
  content: "\ea7a";
}

.siemens-e-car::before {
  content: "\ea7b";
}

.siemens-ease-of-use::before {
  content: "\ea7c";
}

.siemens-edit::before {
  content: "\ea7d";
}

.siemens-efficiency-performance::before {
  content: "\ea7e";
}

.siemens-electrical-machinery::before {
  content: "\ea7f";
}

.siemens-electricity::before {
  content: "\ea80";
}

.siemens-electronics::before {
  content: "\ea81";
}

.siemens-elevator::before {
  content: "\ea82";
}

.siemens-email::before {
  content: "\ea83";
}

.siemens-emissions::before {
  content: "\ea84";
}

.siemens-end-user::before {
  content: "\ea85";
}

.siemens-energy-efficiency::before {
  content: "\ea86";
}

.siemens-environmental-solutions::before {
  content: "\ea87";
}

.siemens-error::before {
  content: "\ea88";
}

.siemens-estimation::before {
  content: "\ea89";
}

.siemens-euro::before {
  content: "\ea8a";
}

.siemens-event::before {
  content: "\ea8b";
}

.siemens-excellent-know-how::before {
  content: "\ea8c";
}

.siemens-exhibition::before {
  content: "\ea8d";
}

.siemens-explore::before {
  content: "\ea8e";
}

.siemens-fabricated-metals::before {
  content: "\ea8f";
}

.siemens-facebook::before {
  content: "\ea90";
}

.siemens-favorite-marked::before {
  content: "\ea91";
}

.siemens-favorite::before {
  content: "\ea92";
}

.siemens-female-changing-room::before {
  content: "\ea93";
}

.siemens-female-disabled-restroom::before {
  content: "\ea94";
}

.siemens-female-restroom::before {
  content: "\ea95";
}

.siemens-female-shower::before {
  content: "\ea96";
}

.siemens-filter::before {
  content: "\ea97";
}

.siemens-first-aid::before {
  content: "\ea98";
}

.siemens-flexibility::before {
  content: "\ea99";
}

.siemens-flight-level-1::before {
  content: "\ea9a";
}

.siemens-flight-level-2::before {
  content: "\ea9b";
}

.siemens-flight-level-3::before {
  content: "\ea9c";
}

.siemens-flight-level::before {
  content: "\ea9d";
}

.siemens-folder-new::before {
  content: "\ea9e";
}

.siemens-folder-open::before {
  content: "\ea9f";
}

.siemens-folder::before {
  content: "\eaa0";
}

.siemens-food-and-beverage::before {
  content: "\eaa1";
}

.siemens-food-shop-food-store::before {
  content: "\eaa2";
}

.siemens-food-vending-machine::before {
  content: "\eaa3";
}

.siemens-food::before {
  content: "\eaa4";
}

.siemens-freezing::before {
  content: "\eaa5";
}

.siemens-full-screen-exit::before {
  content: "\eaa6";
}

.siemens-full-screen::before {
  content: "\eaa7";
}

.siemens-furniture::before {
  content: "\eaa8";
}

.siemens-garbage::before {
  content: "\eaa9";
}

.siemens-gearing::before {
  content: "\eaaa";
}

.siemens-generator::before {
  content: "\eaab";
}

.siemens-glass-stone-cement::before {
  content: "\eaac";
}

.siemens-glass-waste::before {
  content: "\eaad";
}

.siemens-glass::before {
  content: "\eaae";
}

.siemens-global-engineering::before {
  content: "\eaaf";
}

.siemens-goods-elevator::before {
  content: "\eab0";
}

.siemens-group-people::before {
  content: "\eab1";
}

.siemens-gym::before {
  content: "\eab2";
}

.siemens-healthcare::before {
  content: "\eab3";
}

.siemens-help::before {
  content: "\eab4";
}

.siemens-hide::before {
  content: "\eab5";
}

.siemens-history::before {
  content: "\eab6";
}

.siemens-home-small::before {
  content: "\eab7";
}

.siemens-home::before {
  content: "\eab8";
}

.siemens-hourglass::before {
  content: "\eab9";
}

.siemens-hp-ip-turbine::before {
  content: "\eaba";
}

.siemens-hp-turbine::before {
  content: "\eabb";
}

.siemens-image::before {
  content: "\eabc";
}

.siemens-implementation::before {
  content: "\eabd";
}

.siemens-inductive-charging::before {
  content: "\eabe";
}

.siemens-information::before {
  content: "\eabf";
}

.siemens-instagram::before {
  content: "\eac0";
}

.siemens-installation-commissioning::before {
  content: "\eac1";
}

.siemens-interaction-click::before {
  content: "\eac2";
}

.siemens-interaction-touch::before {
  content: "\eac3";
}

.siemens-ip-lp-turbine::before {
  content: "\eac4";
}

.siemens-ip-turbine::before {
  content: "\eac5";
}

.siemens-kanban-board::before {
  content: "\eac6";
}

.siemens-kitchen::before {
  content: "\eac7";
}

.siemens-language::before {
  content: "\eac8";
}

.siemens-leather::before {
  content: "\eac9";
}

.siemens-legal::before {
  content: "\eaca";
}

.siemens-library::before {
  content: "\eacb";
}

.siemens-lifetime::before {
  content: "\eacc";
}

.siemens-like-marked::before {
  content: "\eacd";
}

.siemens-like::before {
  content: "\eace";
}

.siemens-line-chart::before {
  content: "\eacf";
}

.siemens-link-extern::before {
  content: "\ead0";
}

.siemens-link::before {
  content: "\ead1";
}

.siemens-linkedin::before {
  content: "\ead2";
}

.siemens-lng-carrier-ship::before {
  content: "\ead3";
}

.siemens-location::before {
  content: "\ead4";
}

.siemens-locked::before {
  content: "\ead5";
}

.siemens-locker::before {
  content: "\ead6";
}

.siemens-login::before {
  content: "\ead7";
}

.siemens-logout::before {
  content: "\ead8";
}

.siemens-lp-turbine::before {
  content: "\ead9";
}

.siemens-machine-building::before {
  content: "\eada";
}

.siemens-mailbox::before {
  content: "\eadb";
}

.siemens-male-changing-room::before {
  content: "\eadc";
}

.siemens-male-disabled-restroom::before {
  content: "\eadd";
}

.siemens-male-restroom-bin::before {
  content: "\eade";
}

.siemens-male-restroom::before {
  content: "\eadf";
}

.siemens-male-shower::before {
  content: "\eae0";
}

.siemens-manufact::before {
  content: "\eae1";
}

.siemens-marine::before {
  content: "\eae2";
}

.siemens-market-analysis::before {
  content: "\eae3";
}

.siemens-marketing-materials::before {
  content: "\eae4";
}

.siemens-maximize::before {
  content: "\eae5";
}

.siemens-medical-service::before {
  content: "\eae6";
}

.siemens-meeting-room::before {
  content: "\eae7";
}

.siemens-meeting::before {
  content: "\eae8";
}

.siemens-menu::before {
  content: "\eae9";
}

.siemens-metallurgy::before {
  content: "\eaea";
}

.siemens-metro::before {
  content: "\eaeb";
}

.siemens-microphone::before {
  content: "\eaec";
}

.siemens-minimal-marketable-product::before {
  content: "\eaed";
}

.siemens-minimize::before {
  content: "\eaee";
}

.siemens-minimum-viable-product::before {
  content: "\eaef";
}

.siemens-mining::before {
  content: "\eaf0";
}

.siemens-minus::before {
  content: "\eaf1";
}

.siemens-mobile-device::before {
  content: "\eaf2";
}

.siemens-modular::before {
  content: "\eaf3";
}

.siemens-monitor::before {
  content: "\eaf4";
}

.siemens-motor-vehicle::before {
  content: "\eaf5";
}

.siemens-motorcycle-parking::before {
  content: "\eaf6";
}

.siemens-motorcycle::before {
  content: "\eaf7";
}

.siemens-municipalities-and-dsos::before {
  content: "\eaf8";
}

.siemens-museum::before {
  content: "\eaf9";
}

.siemens-nature::before {
  content: "\eafa";
}

.siemens-navigation-left::before {
  content: "\eafb";
}

.siemens-navigation-right::before {
  content: "\eafc";
}

.siemens-news-and-events::before {
  content: "\eafd";
}

.siemens-news::before {
  content: "\eafe";
}

.siemens-no-drinking-water::before {
  content: "\eaff";
}

.siemens-no-smoking::before {
  content: "\eb00";
}

.siemens-notepad::before {
  content: "\eb01";
}

.siemens-notification::before {
  content: "\eb02";
}

.siemens-office-kitchen::before {
  content: "\eb03";
}

.siemens-oil-and-gas::before {
  content: "\eb04";
}

.siemens-oil-platform::before {
  content: "\eb05";
}

.siemens-operation-maintenance::before {
  content: "\eb06";
}

.siemens-optimize::before {
  content: "\eb07";
}

.siemens-options-horizontal::before {
  content: "\eb08";
}

.siemens-options::before {
  content: "\eb09";
}

.siemens-organic-waste::before {
  content: "\eb0a";
}

.siemens-package::before {
  content: "\eb0b";
}

.siemens-pair-programming::before {
  content: "\eb0c";
}

.siemens-paper-waste::before {
  content: "\eb0d";
}

.siemens-paper::before {
  content: "\eb0e";
}

.siemens-parking-garage::before {
  content: "\eb0f";
}

.siemens-parking::before {
  content: "\eb10";
}

.siemens-partnership::before {
  content: "\eb11";
}

.siemens-paste::before {
  content: "\eb12";
}

.siemens-pause::before {
  content: "\eb13";
}

.siemens-pencil::before {
  content: "\eb14";
}

.siemens-performance::before {
  content: "\eb15";
}

.siemens-pharmaceutical::before {
  content: "\eb16";
}

.siemens-phone::before {
  content: "\eb17";
}

.siemens-pin::before {
  content: "\eb18";
}

.siemens-pinned::before {
  content: "\eb19";
}

.siemens-pipeline::before {
  content: "\eb1a";
}

.siemens-plant-design-engineering::before {
  content: "\eb1b";
}

.siemens-plant-operator::before {
  content: "\eb1c";
}

.siemens-plant::before {
  content: "\eb1d";
}

.siemens-play::before {
  content: "\eb1e";
}

.siemens-plus-small::before {
  content: "\eb1f";
}

.siemens-plus::before {
  content: "\eb20";
}

.siemens-point-study::before {
  content: "\eb21";
}

.siemens-post-office::before {
  content: "\eb22";
}

.siemens-power-plant-solution::before {
  content: "\eb23";
}

.siemens-power-supply::before {
  content: "\eb24";
}

.siemens-power-utilities::before {
  content: "\eb25";
}

.siemens-print::before {
  content: "\eb26";
}

.siemens-printing::before {
  content: "\eb27";
}

.siemens-process-ind-and-drives::before {
  content: "\eb28";
}

.siemens-product-increment-l::before {
  content: "\eb29";
}

.siemens-product-increment-m::before {
  content: "\eb2a";
}

.siemens-product-increment-s::before {
  content: "\eb2b";
}

.siemens-product-increment-xl::before {
  content: "\eb2c";
}

.siemens-product-increment::before {
  content: "\eb2d";
}

.siemens-product-owner::before {
  content: "\eb2e";
}

.siemens-product-roadmap::before {
  content: "\eb2f";
}

.siemens-project-office::before {
  content: "\eb30";
}

.siemens-pump-jacks::before {
  content: "\eb31";
}

.siemens-pumps-fans-and-compressors::before {
  content: "\eb32";
}

.siemens-pumps::before {
  content: "\eb33";
}

.siemens-qr-code::before {
  content: "\eb34";
}

.siemens-quality::before {
  content: "\eb35";
}

.siemens-rack-feeder::before {
  content: "\eb36";
}

.siemens-rail-ship-aircraft::before {
  content: "\eb37";
}

.siemens-reading-time::before {
  content: "\eb38";
}

.siemens-recycling::before {
  content: "\eb39";
}

.siemens-redo::before {
  content: "\eb3a";
}

.siemens-redundancy::before {
  content: "\eb3b";
}

.siemens-references::before {
  content: "\eb3c";
}

.siemens-refinery-ship::before {
  content: "\eb3d";
}

.siemens-refinery::before {
  content: "\eb3e";
}

.siemens-refresh::before {
  content: "\eb3f";
}

.siemens-refrigerator::before {
  content: "\eb40";
}

.siemens-reset::before {
  content: "\eb41";
}

.siemens-residual-waste::before {
  content: "\eb42";
}

.siemens-resize::before {
  content: "\eb43";
}

.siemens-restaurant::before {
  content: "\eb44";
}

.siemens-restroom::before {
  content: "\eb45";
}

.siemens-rfid::before {
  content: "\eb46";
}

.siemens-roles::before {
  content: "\eb47";
}

.siemens-roller-rack-archive::before {
  content: "\eb48";
}

.siemens-rooftop-terrace::before {
  content: "\eb49";
}

.siemens-rotor::before {
  content: "\eb4a";
}

.siemens-router::before {
  content: "\eb4b";
}

.siemens-rubber-plastics::before {
  content: "\eb4c";
}

.siemens-safe-agile-team::before {
  content: "\eb4d";
}

.siemens-safe-design-thinking::before {
  content: "\eb4e";
}

.siemens-safe-devops::before {
  content: "\eb4f";
}

.siemens-safe-release-training-engineer::before {
  content: "\eb50";
}

.siemens-safe-value-stream::before {
  content: "\eb51";
}

.siemens-safety::before {
  content: "\eb52";
}

.siemens-sales-support::before {
  content: "\eb53";
}

.siemens-save::before {
  content: "\eb54";
}

.siemens-scalable::before {
  content: "\eb55";
}

.siemens-scrum-master::before {
  content: "\eb56";
}

.siemens-scrum::before {
  content: "\eb57";
}

.siemens-seal-of-approval::before {
  content: "\eb58";
}

.siemens-search-small::before {
  content: "\eb59";
}

.siemens-search::before {
  content: "\eb5a";
}

.siemens-settings::before {
  content: "\eb5b";
}

.siemens-share::before {
  content: "\eb5c";
}

.siemens-shopping-cart::before {
  content: "\eb5d";
}

.siemens-show::before {
  content: "\eb5e";
}

.siemens-shower::before {
  content: "\eb5f";
}

.siemens-sign-language::before {
  content: "\eb60";
}

.siemens-signage-arrow::before {
  content: "\eb61";
}

.siemens-skip-back::before {
  content: "\eb62";
}

.siemens-skip::before {
  content: "\eb63";
}

.siemens-small-car-parking::before {
  content: "\eb64";
}

.siemens-small-car::before {
  content: "\eb65";
}

.siemens-smoking-area::before {
  content: "\eb66";
}

.siemens-soap::before {
  content: "\eb67";
}

.siemens-social-feed::before {
  content: "\eb68";
}

.siemens-social-media::before {
  content: "\eb69";
}

.siemens-solar::before {
  content: "\eb6a";
}

.siemens-sort-ascending::before {
  content: "\eb6b";
}

.siemens-sort-descending::before {
  content: "\eb6c";
}

.siemens-sort-down::before {
  content: "\eb6d";
}

.siemens-sort-up-down::before {
  content: "\eb6e";
}

.siemens-sort-up::before {
  content: "\eb6f";
}

.siemens-sort::before {
  content: "\eb70";
}

.siemens-source-code::before {
  content: "\eb71";
}

.siemens-sprint-team::before {
  content: "\eb72";
}

.siemens-stairs::before {
  content: "\eb73";
}

.siemens-stop::before {
  content: "\eb74";
}

.siemens-storeroom-archive::before {
  content: "\eb75";
}

.siemens-storeroom-for-chairs::before {
  content: "\eb76";
}

.siemens-success::before {
  content: "\eb77";
}

.siemens-support::before {
  content: "\eb78";
}

.siemens-switching::before {
  content: "\eb79";
}

.siemens-tank-farms-silo::before {
  content: "\eb7a";
}

.siemens-technical-data::before {
  content: "\eb7b";
}

.siemens-technical-topics::before {
  content: "\eb7c";
}

.siemens-telegram::before {
  content: "\eb7d";
}

.siemens-telephone::before {
  content: "\eb7e";
}

.siemens-temperature-range::before {
  content: "\eb7f";
}

.siemens-terms-and-conditions::before {
  content: "\eb80";
}

.siemens-textiles::before {
  content: "\eb81";
}

.siemens-think-tank::before {
  content: "\eb82";
}

.siemens-tiktok::before {
  content: "\eb83";
}

.siemens-time-saving::before {
  content: "\eb84";
}

.siemens-tobacco::before {
  content: "\eb85";
}

.siemens-total-prod-sector::before {
  content: "\eb86";
}

.siemens-towel::before {
  content: "\eb87";
}

.siemens-trade-fair::before {
  content: "\eb88";
}

.siemens-traffic-light::before {
  content: "\eb89";
}

.siemens-train::before {
  content: "\eb8a";
}

.siemens-training::before {
  content: "\eb8b";
}

.siemens-tram::before {
  content: "\eb8c";
}

.siemens-transportation-and-logistics::before {
  content: "\eb8d";
}

.siemens-trash::before {
  content: "\eb8e";
}

.siemens-twitter::before {
  content: "\eb8f";
}

.siemens-undo::before {
  content: "\eb90";
}

.siemens-unlocked::before {
  content: "\eb91";
}

.siemens-upload::before {
  content: "\eb92";
}

.siemens-user-story::before {
  content: "\eb93";
}

.siemens-user::before {
  content: "\eb94";
}

.siemens-utilization::before {
  content: "\eb95";
}

.siemens-vertical-mills::before {
  content: "\eb96";
}

.siemens-video-conference-room::before {
  content: "\eb97";
}

.siemens-video-monitoring::before {
  content: "\eb98";
}

.siemens-video::before {
  content: "\eb99";
}

.siemens-virtual-commissioning::before {
  content: "\eb9a";
}

.siemens-virtual-plant::before {
  content: "\eb9b";
}

.siemens-vk::before {
  content: "\eb9c";
}

.siemens-volume-loud::before {
  content: "\eb9d";
}

.siemens-volume-mute::before {
  content: "\eb9e";
}

.siemens-volume-quiet::before {
  content: "\eb9f";
}

.siemens-warning::before {
  content: "\eba0";
}

.siemens-water-and-wastewater::before {
  content: "\eba1";
}

.siemens-wearing::before {
  content: "\eba2";
}

.siemens-wechat::before {
  content: "\eba3";
}

.siemens-weibo::before {
  content: "\eba4";
}

.siemens-wi-fi::before {
  content: "\eba5";
}

.siemens-wimax::before {
  content: "\eba6";
}

.siemens-wind-equipment::before {
  content: "\eba7";
}

.siemens-wood-processing::before {
  content: "\eba8";
}

.siemens-yammer::before {
  content: "\eba9";
}

.siemens-youtube::before {
  content: "\ebaa";
}

.siemens-zoom-in::before {
  content: "\ebab";
}

.siemens-zoom-out::before {
  content: "\ebac";
}